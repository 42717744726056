var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp(target, key, result);
  return result;
};

// src/components/tracker/tracker.ts
var Tracker = class _Tracker {
  constructor() {
    this.mixpanel = window.mixpanel;
    this.debug = localStorage.getItem("debug") === "true" || window.debug_9am;
    this.setupComplete = false;
    this.isWebsite = () => {
      const domain = window.location.hostname;
      return domain === "join9am.com" || domain === "www.join9am.com";
    };
    this.lastPageViewTracked = "";
    this.trackPageView = () => {
      var _a2, _b, _c, _d, _e, _f;
      if (this.lastPageViewTracked === window.location.href) return;
      this.lastPageViewTracked = window.location.href;
      if (!this.mixpanel) {
        console.warn("Mixpanel is not loaded");
        return;
      }
      if (!window.initTracked && this.isWebsite()) {
        const h1OnPage = (_c = (_b = (_a2 = document.querySelector("h1")) == null ? void 0 : _a2.textContent) == null ? void 0 : _b.trim()) != null ? _c : "";
        const footerTitle = (_f = (_e = (_d = document.querySelector("footer h4")) == null ? void 0 : _d.textContent) == null ? void 0 : _e.trim()) != null ? _f : "";
        this.mixpanelTrack("Page View", {
          Title: document.title,
          "H1 on Page": h1OnPage,
          "Footer Title": footerTitle
        });
        window.initTracked = true;
      }
    };
    this.utmSetupComplete = false;
    if (this.mixpanel && window.MIXPANEL_TOKEN) {
      this.mixpanel.init(window.MIXPANEL_TOKEN, {
        debug: this.debug,
        verbose: this.debug,
        cross_subdomain_cookie: true,
        cross_site_cookie: true
      });
    } else if (!this.mixpanel) {
      console.warn("WCL - Mixpanel is not loaded");
    } else if (!window.MIXPANEL_TOKEN) {
      console.warn("WCL - MIXPANEL_TOKEN is not set");
    }
    this.setup();
  }
  track(event, eventProperties) {
    var _a2;
    if (!this.isWebsite()) return;
    if (!this.mixpanel) {
      console.warn("Mixpanel is not loaded");
      return;
    }
    const props = __spreadValues(__spreadValues({}, (_a2 = window.TRACK_DATA) != null ? _a2 : {}), eventProperties != null ? eventProperties : {});
    if (this.debug) {
      console.log("track", {
        event,
        props
      });
    }
    this.mixpanelTrack(event, props);
    try {
      this.trackGoogle(event);
    } catch (error) {
      console.error("Error tracking with Google Analytics", error);
    }
  }
  trackGoogle(event) {
    if (typeof window !== "undefined" && typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({ event });
    }
  }
  setup() {
    if (this.setupComplete) return;
    this.setupComplete = true;
    this.setupUTM();
    window.addEventListener("load", this.trackPageView);
  }
  static getQueryParam(url, param) {
    var _a2;
    const re = new RegExp(`(\\?|&)${param}=([^&]*)`);
    const match = re.exec(url);
    return (_a2 = match == null ? void 0 : match[2]) != null ? _a2 : "";
  }
  setupUTM() {
    var _a2, _b;
    if (this.utmSetupComplete) return;
    this.utmSetupComplete = true;
    const campaignKeywords = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
    const utmParams = {};
    const firstTouch = {};
    const location = window.location.href;
    for (const key of campaignKeywords) {
      const value = _Tracker.getQueryParam(location, key);
      if (value) {
        utmParams[`${key} [last touch]`] = value;
        firstTouch[`${key} [first touch]`] = value;
      }
    }
    if (!this.mixpanel) {
      console.warn("Mixpanel is not loaded");
      return;
    }
    (_a2 = this.mixpanel.people) == null ? void 0 : _a2.set_once(firstTouch);
    (_b = this.mixpanel.people) == null ? void 0 : _b.set(utmParams);
  }
  get cachedTrackingId() {
    var _a2;
    return (_a2 = sessionStorage.getItem("9am.analyticsToken")) != null ? _a2 : "";
  }
  set cachedTrackingId(value) {
    sessionStorage.setItem("9am.analyticsToken", value);
  }
  alias(analyticsToken) {
    if (analyticsToken && analyticsToken !== this.cachedTrackingId) {
      if (!this.mixpanel) {
        console.warn("Mixpanel is not loaded");
        return;
      }
      try {
        this.mixpanel.identify();
        this.mixpanel.alias(analyticsToken);
      } catch (error) {
        console.error("Error aliasing with Mixpanel", error);
      }
      this.cachedTrackingId = analyticsToken;
    }
    if (typeof window !== "undefined" && typeof window._hsq !== "undefined") {
      window._hsq.push(["identify", { token: analyticsToken }]);
    }
  }
  mixpanelTrack(event, eventProperties) {
    if (!this.mixpanel) {
      console.warn("Mixpanel is not loaded");
      return;
    }
    try {
      this.mixpanel.track(event, eventProperties);
    } catch (error) {
      console.error("Error tracking with Mixpanel", error);
    }
  }
};
var tracker = new Tracker();
window.tracker = tracker;

// src/internal/nine-element.ts
import { LitElement } from "lit";
import { property } from "lit/decorators.js";
var NineElement = class extends LitElement {
  constructor() {
    super();
    this.dir = "ltr";
    this.lang = "en";
    Object.entries(this.constructor.dependencies).forEach(([name, component]) => {
      this.constructor.define(name, component);
    });
  }
  emit(name, options) {
    const event = new CustomEvent(name, __spreadValues({
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {}
    }, options));
    this.dispatchEvent(event);
    return event;
  }
  get isReactElement() {
    return Object.keys(this).some((key) => key.startsWith("__react"));
  }
  /* eslint-enable */
  static define(name, elementConstructor = this, options = {}) {
    const currentlyRegisteredConstructor = customElements.get(name);
    if (!currentlyRegisteredConstructor) {
      customElements.define(name, class extends elementConstructor {
      }, options);
      return;
    }
    let newVersion = " (unknown version)";
    let existingVersion = newVersion;
    if ("version" in elementConstructor && elementConstructor.version) {
      newVersion = " v" + elementConstructor.version;
    }
    if ("version" in currentlyRegisteredConstructor && currentlyRegisteredConstructor.version) {
      existingVersion = " v" + currentlyRegisteredConstructor.version;
    }
    if (newVersion && existingVersion && newVersion === existingVersion) {
      return;
    }
    console.warn(
      `Attempted to register <${name}>${newVersion}, but <${name}>${existingVersion} has already been registered.`
    );
  }
};
/* eslint-disable */
// @ts-ignore
NineElement.version = true ? "2.0.34-localdev-1725354404084" : "dev";
NineElement.dependencies = {};
__decorateClass([
  property()
], NineElement.prototype, "dir", 2);
__decorateClass([
  property()
], NineElement.prototype, "lang", 2);

// src/modules/cards/cards.component.ts
import { css, html } from "lit";
var NineCards = class extends NineElement {
  render() {
    return html`
            <section>
                <div class="text">
                    <slot name="text"></slot>
                </div>
                <div class="cardlist">
                    <slot name="cards"></slot>
                </div>
            </section>
        `;
  }
};
NineCards.styles = css`
      :host {
        display: block;
        position: relative;
        z-index: 5;
        max-width: var(--page-max-width);
        margin: var(--section-gap) auto;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 var(--space-xl);
      }

      .text {
        text-align: center;
        margin: 0 auto;
        padding: var(--space-xl) var(--space-md);
        max-width: 680px;
      }

      .cardlist {
        padding: 0 var(--space-md);
      }
    `;

// src/modules/cards/cards.ts
var cards_default = NineCards;
NineCards.define("nine-cards");

// src/modules/content-steps-list/content-step.component.ts
import { css as css2, html as html2 } from "lit";
import { property as property2 } from "lit/decorators.js";

// src/constants/layout.ts
var LAYOUT_SWITCH_AT = 960;

// src/modules/content-steps-list/content-step.component.ts
var NineContentStep = class extends NineElement {
  constructor() {
    super(...arguments);
    this.imageSrc = "";
    this.imageAlt = "";
  }
  firstUpdated() {
    this.dispatchEvent(
      new CustomEvent("content-step-loaded", {
        detail: {
          imageSrc: this.imageSrc,
          imageAlt: this.imageAlt,
          root: this
        },
        bubbles: true
      })
    );
  }
  render() {
    return html2`
      <div class="wrapper">
        <div class="image">
          <img src="${this.imageSrc}" alt="${this.imageAlt}" />
        </div>
        <div class="content">
          <slot name="content"></slot>
        </div>
      </div>
    `;
  }
};
NineContentStep.styles = css2`
    :host {
      display: block;
      max-width: var(--page-max-width);
      margin: var(--section-gap) auto;
      padding: 0 var(--space-md);
    }

    .wrapper {
      display: grid;
      gap: calc(var(--space-sm) * 1.5);
    }

    img {
      border-radius: 50%;
      width: min(100%, 560px);
      aspect-ratio: 1;
      object-fit: cover;
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      :host(:not(:last-of-type)) {
        margin-bottom: calc(var(--section-gap) * 2);
      }

      .wrapper {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: var(--section-gap);
      }

      img {
        display: none;
      }
    }
  `;
__decorateClass([
  property2({ type: String, attribute: "image-src" })
], NineContentStep.prototype, "imageSrc", 2);
__decorateClass([
  property2({ type: String, attribute: "image-alt" })
], NineContentStep.prototype, "imageAlt", 2);

// src/modules/content-steps-list/content-step.ts
var content_step_default = NineContentStep;
NineContentStep.define("nine-content-step");

// src/modules/content-steps-list/content-steps-list.component.ts
import { css as css3, html as html3 } from "lit";
import { property as property3 } from "lit/decorators.js";
import { createRef, ref } from "lit/directives/ref.js";

// src/lib/config.ts
var _a;
var defaultNineConfig = {
  mainScrollTarget: (_a = document.scrollingElement) != null ? _a : window,
  disableProblematicDeviceCheck: false,
  language: "en"
};
var addScrollListener = (listener, options) => {
  const conf = getConfig();
  conf.mainScrollTarget.addEventListener("scroll", listener, options);
  if (conf.mainScrollTarget !== window) {
    window.addEventListener("scroll", listener, options);
  }
};
var removeScrollListener = (listener) => {
  const conf = getConfig();
  conf.mainScrollTarget.removeEventListener("scroll", listener);
  window.removeEventListener("scroll", listener);
};
window.nineConfig = window.nineConfig || defaultNineConfig;
var getConfig = () => {
  const fullConfig = __spreadValues(__spreadValues({}, defaultNineConfig), window.nineConfig);
  if (typeof fullConfig.mainScrollTarget !== "object") {
    console.warn("mainScrollTarget is not an object, using default");
    fullConfig.mainScrollTarget = document.querySelector("*");
  }
  return fullConfig;
};
var getMainScrollPosition = () => {
  const conf = getConfig();
  if (conf.mainScrollTarget instanceof Window) {
    return {
      x: conf.mainScrollTarget.scrollX,
      y: conf.mainScrollTarget.scrollY
    };
  }
  return {
    x: conf.mainScrollTarget.scrollLeft,
    y: conf.mainScrollTarget.scrollTop
  };
};

// src/modules/content-steps-list/content-steps-list.component.ts
var NineContentStepsList = class extends NineElement {
  constructor() {
    super();
    this.elRef = createRef();
    this.screenHeightHalf = 0;
    this.imgHeightHalf = 0;
    this.firstStep = null;
    this.lastStep = null;
    this.currentActiveIndex = null;
    this.setScreenAndImgHeightVariables = () => {
      var _a2;
      const screenHeight = window.innerHeight;
      this.screenHeightHalf = screenHeight / 2;
      const img = (_a2 = this.shadowRoot) == null ? void 0 : _a2.querySelector("img");
      const imgHeight = img == null ? void 0 : img.getBoundingClientRect().height;
      this.imgHeightHalf = (imgHeight != null ? imgHeight : 0) / 2;
    };
    this.handleResize = () => {
      this.setScreenAndImgHeightVariables();
      this.handleScroll();
    };
    this.handleScroll = () => {
      var _a2, _b, _c, _d, _e;
      try {
        const firstStepTopPos = (_a2 = this.firstStep) == null ? void 0 : _a2.getBoundingClientRect().top;
        const lastStepTopPos = (_b = this.lastStep) == null ? void 0 : _b.getBoundingClientRect().top;
        const lastStepHeight = (_c = this.lastStep) == null ? void 0 : _c.getBoundingClientRect().height;
        const navbarHeightString = document.body.style.getPropertyValue("--navbar-height");
        const navbarHeight = navbarHeightString.length > 0 ? parseInt(navbarHeightString) : 0;
        const infobarHeightString = document.body.style.getPropertyValue("--infobar-height");
        const infobarHeight = infobarHeightString.length > 0 ? parseInt(infobarHeightString) : 0;
        if (firstStepTopPos && lastStepTopPos && lastStepHeight) {
          this.setActiveImg();
          const switchPointTop = this.screenHeightHalf - this.imgHeightHalf + navbarHeight / 2 - infobarHeight / 2;
          const switchPointBottom = this.screenHeightHalf + this.imgHeightHalf - lastStepHeight + navbarHeight / 2 - infobarHeight / 2;
          const firstStepFocusBegin = firstStepTopPos < switchPointTop;
          const lastStepFocusEnd = lastStepTopPos <= switchPointBottom;
          const classes = { fixed: false, bottom: false };
          if (firstStepFocusBegin && !lastStepFocusEnd) {
            classes.fixed = true;
          } else if (lastStepFocusEnd) {
            classes.bottom = true;
          }
          (_d = this.elRef.value) == null ? void 0 : _d.classList.toggle("fixed", classes.fixed);
          (_e = this.elRef.value) == null ? void 0 : _e.classList.toggle("image-bottom", classes.bottom);
        }
      } catch (error) {
        console.error(error);
      }
    };
    this.steps = [];
    this.addEventListener(
      "content-step-loaded",
      this.handleContentStepLoaded
    );
  }
  firstUpdated() {
    addScrollListener(this.handleScroll, {
      passive: true
    });
    window.addEventListener("resize", this.handleResize, {
      passive: true
    });
  }
  disconnectedCallback() {
    this.removeEventListener(
      "content-step-loaded",
      this.handleContentStepLoaded
    );
    removeScrollListener(this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }
  // on "content-step-loaded" take src and alt from image and append image to elRef
  handleContentStepLoaded(event) {
    var _a2;
    const index = this.steps.length;
    event.detail.root.dataset.index = index.toString();
    this.steps = [...this.steps, event.detail];
    const img = document.createElement("img");
    img.src = event.detail.imageSrc;
    img.alt = event.detail.imageAlt;
    img.dataset.index = index.toString();
    if (index === 0) img.classList.add("active");
    (_a2 = this.elRef.value) == null ? void 0 : _a2.appendChild(img);
    const steps = this.querySelectorAll("nine-content-step");
    this.firstStep = steps[0];
    this.lastStep = steps[steps.length - 1];
    this.setScreenAndImgHeightVariables();
  }
  setActiveImg() {
    var _a2;
    let nextActiveIndex = 0;
    this.steps.forEach((step) => {
      const stepTopPos = step.root.getBoundingClientRect().top;
      const dataIndex = step.root.dataset.index;
      const switchPoint = this.screenHeightHalf;
      const currentStepFocusBegin = stepTopPos < switchPoint;
      if (currentStepFocusBegin) {
        nextActiveIndex = Number(dataIndex);
      }
    });
    if (nextActiveIndex !== this.currentActiveIndex) {
      this.currentActiveIndex = nextActiveIndex;
      (_a2 = this.shadowRoot) == null ? void 0 : _a2.querySelectorAll("img").forEach((img, i) => {
        img.classList.toggle("active", i === nextActiveIndex);
      });
    }
  }
  render() {
    return html3`
      <section ${ref(this.elRef)}>
        <slot></slot>
      </section>
    `;
  }
};
NineContentStepsList.styles = css3`
    :host {
      display: block;
      position: relative;
    }

    section {
      max-width: var(--page-max-width);
      margin: var(--section-gap) auto;
      --img-width: calc(min(var(--page-max-width), 100vw) / 2);
      --img-gap: calc(var(--section-gap) / 2);
    }

    img {
      width: calc(var(--img-width) - var(--img-gap));
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: calc(50% + var(--space-md));
      transform: translate(calc(-1 * var(--img-width)), var(--y-offset, 0px));
      display: none;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;
    }

    .fixed img {
      position: fixed;
      top: calc(
        50% + var(--navbar-height, 0px) / 2 - var(--infobar-height, 0px) / 2
      );
      --y-offset: -50%;
    }

    img.active {
      opacity: 1;
      pointer-events: all;
    }

    .image-bottom img {
      bottom: 0;
      top: auto;
    }

    ::slotted(*) {
      min-height: calc(var(--img-width) - var(--img-gap));
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      img {
        display: block;
      }
    }
  `;
__decorateClass([
  property3()
], NineContentStepsList.prototype, "steps", 2);

// src/modules/content-steps-list/content-steps-list.ts
var content_steps_list_default = NineContentStepsList;
NineContentStepsList.define("nine-content-steps-list");

// src/modules/coparative-table/comparative-table.component.ts
import { css as css5, html as html4 } from "lit";

// src/modules/coparative-table/comparative-table.global.scss?inline
import { css as css4 } from "lit-element/lit-element.js";
var comparative_table_global_default = css4`nine-comparative-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  font-size: calc(var(--font-size));
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
}
nine-comparative-table table td,
nine-comparative-table table th {
  color: var(--Greys-Charcoal, #212121);
  text-align: center;
  border-bottom: 1px solid var(--Secondary-Dark-Cream, #f2efe7);
  box-sizing: border-box;
  width: 33.3333%;
  padding: 0.7em 0;
}
nine-comparative-table table td:first-child,
nine-comparative-table table th:first-child {
  text-align: left;
  font-weight: 500;
}
nine-comparative-table table td .pad,
nine-comparative-table table th .pad {
  padding: 1em 0.5em;
}
@media screen and (max-width: 400px) {
  nine-comparative-table table td .pad,
  nine-comparative-table table th .pad {
    padding: 1em 0;
  }
}
nine-comparative-table table td > span {
  text-align: center;
  font-family: var(--font-family);
  font-size: calc(var(--font-size) * 0.9);
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */
  letter-spacing: -0.28px;
}
nine-comparative-table table th {
  color: var(--Greys-Charcoal80, rgba(33, 33, 33, 0.8));
  text-align: center;
  font-size: calc(var(--font-size) * 0.7);
  font-weight: 500;
  line-height: 140%;
  /* 14px */
  letter-spacing: -0.2px;
  text-transform: capitalize;
  padding: 1.5em 0;
}
nine-comparative-table table th h6 {
  color: var(--Greys-Charcoal, #212121);
  text-align: center;
  font-size: calc(var(--font-size) * 1.2);
  font-weight: 500;
  line-height: 140%;
  /* 26.6px */
  letter-spacing: -0.76px;
  margin: 0.2em 0 0;
}
nine-comparative-table table th h6::before {
  content: attr(aria-label);
}
nine-comparative-table table th h6[aria-label*="9amHealth"] {
  font-family: var(--font-family-serif);
  font-size: calc(var(--font-size) * 1.3);
  line-height: 120%;
}
@media screen and (max-width: 400px) {
  nine-comparative-table table th h6 {
    font-size: calc(var(--font-size) * 1.1) !important;
  }
}
nine-comparative-table table tr:last-child td {
  border-bottom: none;
}
nine-comparative-table table div[aria-label=yes],
nine-comparative-table table div[aria-label=no] {
  display: block;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  background-size: contain;
}
nine-comparative-table table div[aria-label=yes] + span,
nine-comparative-table table div[aria-label=no] + span {
  display: inline-block;
  padding: 0.5em 0.6em 0;
}
nine-comparative-table table div[aria-label=yes] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiIGZpbGw9IiNGMkVGRTciLz48cGF0aCBmaWxsPSIjMjEyMTIxIiBkPSJNMTMuMjI1IDIyIDggMTYuNzc1bC43NzUtLjc1IDQuNDUgNC40NUwyMi42NzUgMTFsLjguNzc1TDEzLjIyNSAyMloiLz48L3N2Zz4=);
}
nine-comparative-table table div[aria-label=no] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSIzMiIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzIxMjEyMSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtMTcuMTY4IDE2Ljg5OSA0LjQgNC40Ljg0OC0uODUtNC40LTQuMzk5IDQuNTUyLTQuNTUtLjg0OS0uODUtNC41NTEgNC41NTEtNC42NTEtNC42NTEtLjg0OS44NDkgNC42NTIgNC42NTEtNC41IDQuNS44NDguODQ5IDQuNS00LjVaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
}`;

// src/lib/styleInject.ts
function styleInject(css40) {
  if (!css40 || typeof document === "undefined") {
    return;
  }
  const head = document.head || document.getElementsByTagName("head")[0];
  const style = document.createElement("style");
  style.type = "text/css";
  head.appendChild(style);
  style.appendChild(document.createTextNode(css40));
}
var styleInject_default = styleInject;

// src/modules/coparative-table/comparative-table.component.ts
styleInject_default(comparative_table_global_default);
var NineComparativeTable = class extends NineElement {
  render() {
    return html4`
      <section>
        <div class="center-content">
          <slot></slot>
        </div>
        <div class="table-space">
          <table aria-hidden="true">
            <tr>
              <td></td>
              <td>
                <nine-bg
                  variant="animate-primary"
                  full-color="true"
                  ball=""
                  class="highlight"
                  fade="false"
                  curve="undefined"
                  fullcolor
                ></nine-bg>
              </td>
              <td></td>
            </tr>
          </table>
          <slot name="table"></slot>
        </div>
      </section>
    `;
  }
};
NineComparativeTable.styles = css5`
    :host {
      margin: var(--section-gap) 0;
      display: block;
      position: relative;
      z-index: 3;
      padding: 0 1rem;
    }

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .center-content {
      text-align: center;
      padding: 0 var(--space-md);
    }

    .table-space {
      position: relative;
      width: 100%;
      max-width: 720px;
      margin: 2em auto 0;
      display: grid;
      grid-template-areas: 'table';
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .highlight {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      width: auto !important;
      bottom: 0;
      border-radius: 8px;
      overflow: hidden;
    }

    table {
      grid-area: table;
    }
    table td {
      position: relative;
      width: 33.333%;
    }

    ::slotted(table) {
      position: relative;
      z-index: 2;
      grid-area: table;
    }
  `;

// src/modules/coparative-table/comparative-table.ts
var comparative_table_default = NineComparativeTable;
NineComparativeTable.define("nine-comparative-table");

// src/modules/expandable-items/expandable-items.component.ts
import { html as html5, unsafeCSS } from "lit";
import { property as property4, queryAssignedNodes } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/modules/expandable-items/expandable-items.scss?inline
import { css as css6 } from "lit-element/lit-element.js";
var expandable_items_default = css6`:host {
  display: block;
  padding: 0 var(--space-md);
  position: relative;
  z-index: 2;
}

section {
  max-width: var(--page-max-width);
  margin: var(--section-gap) auto;
}

.text {
  text-align: center;
  margin: 0 auto;
  padding: 0 var(--space-md) calc(var(--space-sm) * 2.5);
  max-width: 680px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.left {
  width: 40%;
}

.right {
  width: 40%;
  aspect-ratio: 474/684;
  position: relative;
}
.right img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  transition: all 0.6s ease;
  z-index: -1;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 960px) {
  .text {
    text-align: left;
    margin: 0;
    padding: 0 0 var(--space-lg);
    max-width: 100%;
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;
  }
}`;

// src/modules/expandable-items/expandable-items.global.scss?inline
import { css as css7 } from "lit-element/lit-element.js";
var expandable_items_global_default = css7`nine-expandable-items > *:not(:last-child) nine-dropdown {
  margin-bottom: 1.5rem;
}`;

// src/modules/expandable-items/expandable-items.component.ts
styleInject_default(expandable_items_global_default);
var NineExpandableItems = class extends NineElement {
  constructor() {
    super(...arguments);
    this.images = [];
    this.activeDropdown = 0;
    this.handleToggle = (e) => {
      const dropdowns = this.querySelectorAll("nine-dropdown");
      const index = Array.from(dropdowns).findIndex((dropdown) => dropdown.ddRef === e.detail.ref);
      this.setActiveDropdown(index);
    };
    this.handleMutationChanges = () => {
      this.setActiveDropdown(0);
      const elements = this._elements.filter((element) => element instanceof HTMLElement);
      this.images = elements.map((element) => element.querySelector("img")).filter(Boolean);
    };
    this.addMutationObservers = () => {
      this.observer = new MutationObserver(this.handleMutationChanges);
      this.observer.observe(this, {
        childList: true
      });
    };
  }
  setActiveDropdown(index) {
    const dropdowns = this.querySelectorAll("nine-dropdown");
    dropdowns.forEach((dropdown, i) => {
      dropdown.open = i === index;
    });
    this.activeDropdown = index;
  }
  addListeners() {
    var _a2;
    (_a2 = this.shadowRoot) == null ? void 0 : _a2.addEventListener("nine-dropdown:toggle", this.handleToggle);
  }
  firstUpdated() {
    this.addListeners();
    this.handleMutationChanges();
    this.addMutationObservers();
  }
  renderImages() {
    const images = this.images.map(
      (img, i) => html5`<img
          src=${img.src}
          alt=${img.alt}
          class=${classMap({ hidden: true, visible: i === this.activeDropdown })}
        />`
    );
    return images;
  }
  render() {
    return html5`
      <section>
        <div class="text"><slot name="text"></slot></div>
        <div class="flex">
          <div class="left">
            <slot></slot>
          </div>
          <div class="right">${this.renderImages()}</div>
        </div>
      </section>
    `;
  }
};
NineExpandableItems.styles = unsafeCSS(expandable_items_default);
__decorateClass([
  queryAssignedNodes({ flatten: true })
], NineExpandableItems.prototype, "_elements", 2);
__decorateClass([
  property4()
], NineExpandableItems.prototype, "images", 2);
__decorateClass([
  property4()
], NineExpandableItems.prototype, "activeDropdown", 2);

// src/modules/expandable-items/expandable-items.ts
var expandable_items_default2 = NineExpandableItems;
NineExpandableItems.define("nine-expandable-items");

// src/modules/footer/footer.component.ts
import { css as css10, html as html6 } from "lit";
import { property as property5, queryAssignedNodes as queryAssignedNodes2 } from "lit/decorators.js";

// src/constants/gradient.ts
import { css as css8 } from "lit";
var bgGradientSunrise = css8`
  background-image: var(
    --wcl-bg-gradient-sunrise,
    linear-gradient(
      var(--gradient-dir),
      var(--color-sunrise-blue) 13.49%,
      var(--color-sunrise-pink) 51.22%,
      var(--color-sunrise-orange) 85.11%
    )
  );
`;
var bgGradientSunriseLight = css8`
  background-image: var(
    --wcl-bg-gradient-sunrise-light,
    linear-gradient(var(--gradient-dir), #a6c6ff 5.3%, #fad0e8 48.44%, #ffd199 90.45%)
  );
`;
var bgGradientSunriseLightest = css8`
  background-image: var(
    --wcl-bg-gradient-sunrise-lightest,
    linear-gradient(var(--gradient-dir), #c1d7fb 13.49%, #fddee9 50.42%, #ffdfb4 85.11%)
  );
`;
var bgGradientSunriseLightRepeating = css8`
  background-image: var(
    --wcl-bg-gradient-sunrise-repeating,
    linear-gradient(
      var(--gradient-dir),
      rgba(128, 174, 255, 0.6) 0.36%,
      rgba(247, 189, 230, 0.6) 49.61%,
      rgba(255, 189, 112, 0.6) 99.86%,
      rgba(247, 189, 230, 0.6) 148.1%,
      rgba(128, 174, 255, 0.6) 193.33%
    )
  );
`;
var bgGradientAfternoonLight = css8`
  background-image: var(
    --wcl-bg-gradient-afternoon,
    linear-gradient(var(--gradient-dir), #fffcf3 12.24%, #b0f2ce 54.34%, #ffefc7 87.78%)
  );
`;
var bgGradientDusk = css8`
  background-image: var(
    --wcl-bg-gradient-dusk,
    linear-gradient(var(--gradient-dir), #0e3763 0%, #5b4855 52.6%, #8a6947 100%)
  );
`;

// src/modules/footer/footer.global.scss?inline
import { css as css9 } from "lit-element/lit-element.js";
var footer_global_default = css9`nine-footer div[slot=end] ul li a {
  font-weight: 400;
  font-size: calc(var(--sm-scale) * 0.75);
  line-height: 140%;
  color: var(--color-charcoal-80);
}

nine-footer div[slot=right-end] ul li a,
nine-footer div[slot=right-start] ul li a {
  font-size: calc(var(--sm-scale) * 0.88);
  line-height: 140%;
  letter-spacing: -0.02em;
}

nine-footer ul li a:hover {
  text-decoration: underline;
}

nine-footer [slot=partner] {
  margin: var(--space-md) auto 0;
}
nine-footer [slot=partner] img {
  max-width: 100%;
  max-height: calc(var(--space-sm) * 10.5);
}
@media screen and (min-width: 800px) {
  nine-footer [slot=partner] {
    margin: var(--space-md) 0 var(--space-lg);
  }
}`;

// src/modules/footer/footer.component.ts
styleInject_default(footer_global_default);
var NineFooter = class extends NineElement {
  constructor() {
    super(...arguments);
    this.gradient = "true";
    this.imageSrc = "";
    this.imageAlt = "";
    this.variant = "";
    this.noMargin = false;
  }
  firstUpdated() {
    setTimeout(() => {
      this._certificateNodes.forEach((certificate) => {
        var _a2, _b;
        (_b = (_a2 = this.shadowRoot) == null ? void 0 : _a2.querySelector(".certificate-mobile")) == null ? void 0 : _b.appendChild(certificate.cloneNode(true));
      });
    }, 10);
  }
  render() {
    return html6`
      <footer variant="${this.variant}" gradient="${this.gradient}">
        <div class="center">
          <div class="text">
            <slot name="text"></slot>
          </div>
          <slot name="center"></slot>
          <div class="mid">
            <div>
              <slot name="left"></slot>
            </div>
            <div class="right">
              <slot name="right-start"></slot>
              <slot name="right-end"></slot>
              <div>
                <slot name="language-select"></slot>
                <div class="certificate certificate-desktop">
                  <slot name="certificate"></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="partner">
            <slot name="partner"></slot>
          </div>
          <div class="certificate certificate-mobile"></div>
          <div>
            <slot name="meta"></slot>
          </div>

          <div class="end">
            <slot name="end"></slot>
          </div>
        </div>

        <div class="ball"></div>
      </footer>
    `;
  }
};
NineFooter.styles = css10`
    :host {
      display: block;
      margin: var(--section-gap) 0 0;
    }

    :host([no-margin]) {
      margin: 0;
    }

    .flex {
      display: flex;
    }

    footer {
      position: relative;
      z-index: 1;
    }

    footer:not([gradient='false'])::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: calc(var(--background-gradient-height, 1200px) + 300px);
      z-index: -1;
      opacity: 0.6;
      pointer-events: none;
      --gradient-dir: 0;
      background-image: linear-gradient(
        var(--gradient-dir),
        #a6c6ff 20%,
        #fad0e8 40%,
        #ffd199 70%,
        var(--color-cream) 100%
      );
    }

    .center {
      position: relative;
      margin: 0 auto;
      max-width: var(--page-max-width);
      padding: calc(var(--scale) * 10) var(--space-md) var(--space-xl);
      z-index: 2;
      box-sizing: border-box;
    }

    .text {
      text-align: center;
      padding: var(--space-md) 0 calc(var(--scale) * 6);
      width: min(100%, 570px);
      margin: 0 auto;
    }

    .ball {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
    }

    .ball::after {
      --size: min(max(120%, 2100px), 2100px);
      --gradient-dir: 80deg;
      content: '';
      display: block;
      width: var(--size);
      height: auto;
      aspect-ratio: 1;
      position: absolute;
      top: 0;
      left: 50%;
      border-radius: 50%;
      transform: translateX(-50%);
      background-position: center;
      background-size: min(100vw, 120%) 100%;
      ${bgGradientSunrise};
    }

    .mid,
    .right,
    ::slotted([slot='left']) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    footer[variant='success'] .mid,
    footer[variant='success'] .partner,
    footer[variant='success'] .certificate-mobile {
      display: none;
    }

    ::slotted([slot='center']) {
      text-align: center;
    }

    .partner {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
    }

    .certificate {
      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-gap: calc(var(--space-sm) * 1.5);
    }

    .certificate ::slotted(img),
    .certificate-mobile img {
      width: auto;
      max-width: calc(var(--space-sm) * 8.75);
      max-height: calc(var(--space-sm) * 7.5);
    }

    .certificate-mobile {
      margin-top: var(--space-md);
    }

    .certificate-desktop {
      display: none;
    }

    @media screen and (min-width: 440px) {
      footer:not([gradient='false'])::before {
        height: calc(var(--background-gradient-height, 1350px) + 150px);
      }
    }

    @media screen and (min-width: 640px) {
      footer:not([gradient='false'])::before {
        height: var(--background-gradient-height, 1500px);
      }
    }

    @media screen and (min-width: 800px) {
      .mid {
        margin-bottom: var(--space-lg);
      }

      .right {
        flex-direction: row;
        grid-gap: var(--space-xxl);
      }

      .certificate {
        justify-content: space-between;
      }

      .certificate-mobile {
        display: none;
      }

      .certificate-desktop {
        display: flex;
        margin-top: var(--space-lg);
      }

      .partner {
        grid-template-columns: 1fr 1fr 1fr;
        gap: var(--space-sm);
      }
    }

    @media screen and (min-width: 1100px) {
      .text {
        padding: var(--space-md) 0 calc(var(--scale) * 8.5);
      }

      .mid,
      .end {
        flex-direction: row;
      }

      .mid {
        gap: var(--space-xxl);
      }

      ::slotted([slot='left']) {
        width: calc(var(--space-sm) * 20.25);
      }

      .right {
        gap: var(--space-lg);
      }

      .certificate-desktop {
        flex: 1;
        justify-content: flex-end;
      }
    }
  `;
__decorateClass([
  property5({ type: String })
], NineFooter.prototype, "gradient", 2);
__decorateClass([
  property5({ type: String })
], NineFooter.prototype, "imageSrc", 2);
__decorateClass([
  property5({ type: String })
], NineFooter.prototype, "imageAlt", 2);
__decorateClass([
  property5({ type: String })
], NineFooter.prototype, "variant", 2);
__decorateClass([
  property5({ type: Boolean, attribute: "no-margin" })
], NineFooter.prototype, "noMargin", 2);
__decorateClass([
  queryAssignedNodes2({ slot: "certificate", flatten: true })
], NineFooter.prototype, "_certificateNodes", 2);

// src/modules/footer/footer.ts
var footer_default = NineFooter;
NineFooter.define("nine-footer");

// src/modules/hero/hero.component.ts
import { css as css19, html as html7, unsafeCSS as unsafeCSS2 } from "lit";
import { property as property6 } from "lit/decorators.js";

// src/modules/hero/hero.global.scss?inline
import { css as css11 } from "lit-element/lit-element.js";
var hero_global_default = css11`nine-hero *[slot=overlay] > img {
  max-width: 100%;
  border-radius: 8px;
  filter: var(--light-drop-shadow);
  height: auto !important;
}`;

// src/modules/hero/hero.scss?inline
import { css as css12 } from "lit-element/lit-element.js";
var hero_default = css12`:host {
  background: var(--color-cream);
  display: block;
  position: relative;
  z-index: 2;
  margin-bottom: calc(var(--margin-bottom, -6rem) + 6rem);
}

.img-c[center-narrow=true] {
  margin: 0 auto;
  max-width: var(--page-max-width-narrow);
}

:host([background=none]) {
  background: none;
}

body {
  opacity: 0;
}

section {
  box-sizing: border-box;
  padding: 0 var(--space-sm);
  --pad-top: calc(var(--scale) * 4);
  padding-top: var(--pad-top);
  height: 100vh;
}

section[v=rising]::after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 75vh;
  z-index: -1;
  --gradient-dir: 180deg;
  --color-scale: 1.4;
  --color-delay: 1.2;
  opacity: calc(var(--img-o) * var(--color-scale) - var(--color-delay));
  box-shadow: 0 -13vh 3vh 8vh #a6c6ff, 0 10vh 8vh 8vh #ffd199;
}

.logo {
  width: min(36vw, 100%);
}
.logo img {
  display: block;
  max-width: 100%;
}

.image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: max(45vh, 42vw);
  border-radius: 50% 50% 0 0;
  overflow: hidden;
}

.image img {
  object-fit: cover;
  object-position: 50% 25%;
}

::slotted([slot=logo]) {
  display: block;
  width: auto;
  max-width: 100%;
  margin-bottom: var(--logo-margin-bottom, 0);
}

section[logo-custom-size=true] ::slotted([slot=logo]) {
  width: var(--width, 100%);
  aspect-ratio: var(--aspect-ratio, auto);
  height: auto;
  object-fit: contain;
  object-position: 0 0;
}

::slotted(h1) {
  margin: 2.5rem 0 0.5em;
}

.action {
  margin: var(--space-md) 0 0.5em;
}

.content {
  padding: var(--space-xl) 0;
  max-width: 100vw;
}

.wrap {
  height: 100%;
}

.trust img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 960px) {
  :host {
    margin-bottom: var(--margin-bottom, 0);
  }
}`;

// src/modules/hero/variant.article.scss?inline
import { css as css13 } from "lit-element/lit-element.js";
var variant_article_default = css13`section[v=article] {
  display: block;
  position: relative;
  z-index: 2;
  height: auto;
  background: var(--color-cream);
  --img-w: 53%;
  padding-top: 0;
}
section[v=article] .img-c {
  max-width: var(--section-max-width, calc(var(--page-max-width)));
  margin: 0 auto;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
section[v=article] .wrap {
  padding: var(--space-sm) 0 var(--space-lg);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
section[v=article] .wrap .content {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
section[v=article] .subtitle {
  margin-top: 1em;
}
section[v=article] .action {
  margin: 0;
}
section[v=article] .caption {
  order: 5;
  margin-left: -2px;
  margin-top: 0.8em;
}
section[v=article] .image {
  position: relative;
  top: auto;
  aspect-ratio: 3/1.8;
  border-radius: 8px;
  margin: 0;
  height: auto;
}
section[v=article] .image .img-crop, section[v=article] .image img {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1024px) {
  section[v=article] .img-c {
    --border-size: 2.5em;
    margin-bottom: 2.5em;
  }
  section[v=article] .img-c::after {
    content: "";
    display: block;
    border-bottom: 1px solid var(--color-gray-light);
    height: var(--border-size);
  }
  section[v=article] .wrap {
    padding: var(--space-xl) var(--space-md) 0 var(--space-xxxl);
    min-height: 400px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: calc(100% - var(--img-w));
    margin-left: auto;
    flex-direction: column;
    padding-bottom: var(--space-lg);
  }
  section[v=article] .image {
    position: absolute;
    top: 0;
    right: calc(100% - var(--img-w));
    height: auto;
    bottom: var(--border-size);
    margin: 0;
    aspect-ratio: initial;
  }
  section[v=article] .caption {
    order: -1;
    margin-bottom: 1.4em;
  }
}`;

// src/modules/hero/variant.ball.ts
import { css as css14 } from "lit";
var variantBallStyles = css14`
  section[v="ball"] {
    --bs: max(110vh, 120vw);
    position: relative;
    --gradient-dir: 0;
    max-height: 1300px;
    background-image: linear-gradient(
      var(--gradient-dir),
      #fffdf4 0%,
      #fbdef3 22.31%,
      #ffdeb8 80.92%
    );
  }

  section[v="ball"] .img-c {
    height: 90%;
  }

  section[v="ball"]:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -5%);
    width: var(--bs);
    max-width: 1600px;
    aspect-ratio: 1;
    border-radius: 50%;
    --gradient-dir: 60deg;

    background-image: linear-gradient(
      var(--gradient-dir),
      #fffdf4 5.85%,
      #fbdef3 40.31%,
      #ffdeb8 75.92%
    );
  }

  section[v="ball"] .content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    position: relative;
    z-index: 2;
    max-width: 980px;
    margin: 0 auto;
    flex-direction: column;
  }
`;
var variant_ball_default = variantBallStyles;

// src/modules/hero/variant.gradient.ts
import { css as css15 } from "lit";
var variantGradient = css15`
  section[v="gradient"] {
    --bs: none;
    position: relative;
    --gradient-dir: 180deg;
    overflow: hidden;
    height: auto;
    padding-left: var(--pad-x, var(--space-md));
    padding-right: var(--pad-x, var(--space-md));

    background-image: linear-gradient(
      var(--gradient-dir),
      #d7e9f9 5%,
      #acd4ff 30.31%,
      #b8d8fe 60.92%,
      #d7e9f9 90%
    );
  }

  section[v="gradient"] .content {
    text-align: center;
    height: auto;
    z-index: 1;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    width: min(660px, 100%);
    margin: 0 auto;
    padding: 4em 0 1em;
  }

  section[v="gradient"]:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: var(--bs);
    width: 120%;
    aspect-ratio: 3;
    border-radius: 100% 100% 0 0;
    --gradient-dir: 180deg;

    background-image: linear-gradient(
      var(--gradient-dir),
      #dbe9f9 0,
      var(--color-cream) 50%
    );
  }

  section[v="gradient"] .title {
    margin-bottom: var(--space-md);
  }

  section[v="gradient"] ::slotted([slot="below"]) {
    display: flex;
    grid-gap: var(--space-md);
    flex-direction: column;
  }

  section[v="gradient"] .below {
    margin-bottom: 4.5em;
  }

  @media (min-width: ${LAYOUT_SWITCH_AT - 200}px) {
    section[v="gradient"] ::slotted([slot="below"]) {
      grid-gap: var(--space-xl);
      flex-direction: row;
    }

    section[v="gradient"] .content {
      padding: 6em 0 5em;
    }

    section[v="gradient"] .below {
      margin-bottom: 8em;
    }
  }
`;
var variant_gradient_default = variantGradient;

// src/modules/hero/variant.halfball.ts
import { css as css16 } from "lit";
var variantHalfballStyles = css16`
  section[v="halfball"] {
    --size: min(150vw, 1400px);
    --w: var(--page-max-width-wide);
    --h: min(748px);
    --content-w: 388px;
    height: auto;
    position: relative;
    margin: 0 auto;
    margin-top: 0;
    z-index: 1;
    padding: 0;
    /* min-height: var(--h); */
    /* min-height: calc(var(--w) * 0.35); */
  }

  section[v="halfball"] .title {
    margin-bottom: var(--space-md);
  }

  section[v="halfball"] .subtitle {
    text-shadow: -1px 1px 3px var(--color-cream), 1px 0px 3px var(--color-cream);
  }

  section[v="halfball"] .wrap {
    max-width: var(--page-max-width);
    margin: 0 auto;
  }

  section[v="halfball"] .content {
    /* height: min(220%, 220vw); */
    position: relative;
    padding: 6.5em var(--space-lg) 0;
    padding-bottom: 93vw;
    display: flex;
    flex-direction: column;

    /* padding-bottom: min(800px, 110vw); */
  }

  section[v="halfball"] .img-c {
    position: relative;
  }

  section[v="halfball"] .image {
    position: absolute;
    right: auto;
    top: 100%;
    left: 100vw;
    transform: translate(-50%, -50%);
    --img-size: min(1400px, 220vw);
    height: var(--img-size);
    width: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    z-index: -5;
    pointer-events: none;
  }

  section[v="halfball"] .img-crop {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5000px;
  }

  section[v="halfball"] .img-crop::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
    --gradient-dir: -90deg;
    ${bgGradientSunriseLight}
    background-size: 80% 100%;
  }

  section[v="halfball"] .image img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: calc(var(--img-size) / 2);
    width: calc(var(--img-size) / 2);
    object-fit: cover;
  }

  section[v="halfball"] .below {
    padding: var(--space-md) var(--space-lg);
  }

  @media (min-width: ${LAYOUT_SWITCH_AT - 200}px) {
    section[v="halfball"] .image {
      transform: translate(-50%, -50%);
      height: min(220%, 120vw);
    }

    section[v="halfball"] .image img {
      top: 0;
      height: 50%;
      width: 50%;
    }
    section[v="halfball"] .image::after {
      opacity: 0.45;
    }

    section[v="halfball"] .content {
      position: relative;
      max-width: 50%;
      min-height: var(--h);
      padding: var(--header-height, 5rem) var(--space-xl);
      box-sizing: border-box;
      justify-content: center;
    }

    section[v="halfball"] .subtitle {
      padding-right: 2em;
      max-width: 440px;
    }
  }

  @media (min-width: 1370px) {
    section[v="halfball"] .image {
      transform: translate(-50%, -50%);
      height: min(220%, 120vw);
    }
    section[v="halfball"] .image img {
      width: 50%;
      height: 50%;
    }
    section[v="halfball"] .content {
      max-width: 52%;
    }
  }
`;
var variant_halfball_default = variantHalfballStyles;

// src/modules/hero/variant.rising.ts
import { css as css17 } from "lit";
var variantRisingStyles = css17`
  section[v="rising"] {
    --size: min(145vw, 110vh, 1400px);
    height: auto;
    min-height: 100vh;
  }

  section[v="rising"] .img-c {
    position: relative;
  }

  section[v="rising"] .image {
    height: var(--size);
    width: var(--size);
    left: 50%;
    bottom: 0%;
    transform: scale(1) translateY(55%) translateX(-50%);
  }

  section[v="rising"] .image img {
    position: absolute;
    top: 0;
    left: 50%;
    height: 45%;
    width: 100%;
    max-width: 100%;
    transform: translateX(-50%);
    border-radius: 0;
    overflow: hidden;
    opacity: calc(1 - var(--img-o));
    object-fit: cover;
  }

  section[v="rising"] .img-crop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5000px;
    overflow: hidden;
    --gradient-dir: 180deg;
    ${bgGradientSunrise};
  }

  section[v="rising"] .img-crop::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(33, 33, 33, 0.35) 0%,
      rgba(33, 33, 33, 0) 39.55%
    );
    z-index: 1;
    height: 45%;
    opacity: calc(1 - var(--img-o));
  }

  section[v="rising"] .content {
    text-align: center;
    height: auto;
    z-index: 2;
    position: static;
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-md) 0;
    padding-bottom: 49vw;
    padding-top: 1.4em;
    min-height: min(94vh, 790px);
  }

  section[v="rising"] .caption,
  section[v="rising"] .action {
    z-index: 2;
    position: relative;
  }

  section[v="rising"] .overlay {
    width: 330px;
    max-width: 100%;
    position: relative;
    margin: 2em auto 0;
    z-index: 2;
  }

  section[v="rising"][hideOverlayMobile="true"] .image {
    --size: min(calc(120vh - 118px), 1000px);
  }

  section[v="rising"][hideOverlayMobile="true"] .overlay {
    display: none;
  }

  section[v="rising"] .trust {
    position: absolute;
    z-index: 3;
    top: min(calc(var(--size) + 15.9em), calc(100vh - 7.6em));
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
    width: calc(var(--size) * 0.56);
    opacity: calc(1.5 - var(--img-o));
  }

  /* Title and Subtitle max width */
  section[v="rising"] .title,
  section[v="rising"] .subtitle {
    max-width: var(--page-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${LAYOUT_SWITCH_AT - 200}px) {
    section[v="rising"] {
      --size: min(150vw, 1300px, max(110vh, 900px));
      --height: min(max(100vh, 800px), 1300px);
      min-height: 0;
      height: var(--height);
      position: relative;
    }
    section[v="rising"] .trust {
      bottom: 0;
      top: auto;
      transform: translateX(-50%) translateY(40%);
    }
    section[v="rising"] .img-c {
      position: static;
    }
    section[v="rising"][hideOverlayMobile="true"] .image {
      --size: max(min(1200px, 135vh), 60vw);
      transform: scale(1) translateY(60%) translateX(-50%);
    }
    section[v="rising"] .overlay {
      position: absolute;
      display: block;
      width: 330px;
      left: 50%;
      top: 50%;
      transform: translateY(0) translateX(-150%);
    }
    section[v="rising"] .content {
      padding-top: max(min(10em, 6vh), 3em);
    }
  }

  @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
    section[v="rising"][hideOverlayMobile="true"] .overlay {
      display: block;
    }
  }

  @media screen and (max-height: 799px) {
    section[v="rising"] .trust {
      transform: translateX(-50%) translateY(-10%);
    }
  }

  @media screen and (max-height: 599px) {
    section[v="rising"][hideOverlayMobile="true"] .overlay {
      display: none;
    }
    section[v="rising"][hideOverlayMobile="true"] .image {
      --size: max(min(1200px, 135vh), 80vw);
      transform: scale(1) translateY(50%) translateX(-50%);
    }
  }

  @media screen and (max-height: 599px) and (max-width: ${LAYOUT_SWITCH_AT - 200}px) {
    section[v="rising"] .trust {
      width: calc(var(--size) * 0.75);
      transform: translateX(-50%) translateY(450%);
    }
  }
`;
var variant_rising_default = variantRisingStyles;

// src/modules/hero/variant.split.ts
import { css as css18 } from "lit";
var variantSplitStyles = css18`
  section[v="split"] {
    --size: min(150vw, 110vh, 1400px);
    --h: calc(100vh - 4rem);
    --w: var(--page-max-width-wide);
    --img-h: max(40vh, 300px);
    --content-w: 388px;
    overflow: hidden;
    height: auto;
    position: relative;
    margin: 0 auto;
    margin-top: 4.5rem;
    padding: 0;
    max-width: var(--w);
    min-height: calc(var(--w) * 0.35);
  }

  section[v="split"] .subtitle {
    margin-top: var(--space-sm);
    order: 10;
    margin-top: 1.5em;
    text-align: left;
    border-top: 1px solid #00000022;
    padding-top: 1.1em;
    padding-right: 1em;
  }

  section[v="split"][textAlign="left"] .subtitle {
    border-top: none;
  }

  section[v="split"] .image {
    border-radius: 0;
    transform: scale(1);
    position: absolute;
    left: var(--space-md);
    right: var(--space-md);
    top: 0;
    width: auto;
    border-radius: 8px;
    overflow: hidden;
    height: var(--img-h);
  }

  section[v="split"] .image img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  section[v="split"][largeImage="true"] .image {
    aspect-ratio: 800/648;
    max-height: 80vh;
    max-width: calc(100% - (var(--space-md) * 2));
  }

  section[v="split"] .wrap {
    position: relative;
    right: var(--space-md);
    margin-top: var(--img-h);
    bottom: auto;
    left: 0;
    padding: var(--space-sm) var(--space-md);
    padding-bottom: 0;
    box-sizing: border-box;
    height: auto;
    width: auto;
    height: auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  section[v="split"][textAlign="left"] .wrap {
    text-align: left;
  }

  section[v="split"] .content {
    width: min(100%, var(--content-w));
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
    display: flex;
    flex-direction: column;
  }

  section[v="split"][textAlign="left"] .content {
    padding-top: calc(var(--space-sm) * 1.75);
    width: 100%;
  }

  section[v="split"] .trust {
    width: min(60vw, 220px);
    margin: 1.5em auto 0;
  }

  section[v="split"] .below {
    padding: 0 var(--space-md) 0;
    width: min(100%, var(--content-w));
    margin: 0 auto;
  }

  section[v="split"][textAlign="left"] .below {
    width: 100%;
  }

  @media (min-width: ${LAYOUT_SWITCH_AT}px) {
    section[v="split"] {
      margin-top: 5rem;
      height: calc(100vh - 8rem);
      max-height: calc(var(--w) * 0.5);
      --below-h: 52px;
      margin-bottom: var(--below-h);
      overflow: visible;
    }

    section[v="split"] .image {
      width: calc(50% - var(--space-md));
      height: auto;
      border-radius: 8px;
      overflow: hidden;
    }

    section[v="split"][largeImage="true"] .image {
      width: calc(var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][primary-side="left"] .image {
      width: calc(var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][primary-side="right"] .image {
      width: calc(100% - var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][primary-side="left"][flip="true"] .image {
      width: calc(100% - var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][primary-side="right"][flip="true"] .image {
      width: calc(var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][keep-img-ratio] .image img {
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    section[v="split"] .title {
      margin: 0 -1em;
    }

    section[v="split"][textAlign="left"] .title {
      margin: 0;
    }

    section[v="split"][flip="true"] .image {
      left: auto;
      right: var(--space-md);
    }

    section[v="split"] .wrap {
      position: absolute;
      top: 0;
      width: calc(50% - var(--space-md));
      bottom: 0;
      left: 50%;
      margin-top: 0;
      margin-right: 0;
    }

    section[v="split"][largeImage="true"] .wrap {
      width: calc(35% - var(--space-md));
      left: 65%;
      aspect-ratio: 800/1078;
    }

    section[v="split"][primary-side="left"] .wrap {
      width: calc(100% - var(--split-image-width, 57%) - var(--space-md));
      left: var(--split-image-width, 57%);
    }

    section[v="split"][primary-side="right"] .wrap {
      width: calc(var(--split-image-width, 57%) - var(--space-md));
      left: calc(100% - var(--split-image-width, 57%));
    }

    section[v="split"][primary-side="left"][flip="true"] .wrap {
      width: calc(var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][primary-side="right"][flip="true"] .wrap {
      width: calc(100% - var(--split-image-width, 57%) - var(--space-md));
    }

    section[v="split"][flip="true"] .wrap {
      right: auto;
      left: var(--space-md);
    }

    section[v="split"] .action {
      margin-top: var(--space-lg);
    }

    section[v="split"] .subtitle {
      order: 0;
      opacity: 0.8;
      border-top: none;
      padding-top: 0;
      text-align: center;
      margin-top: 1em;
    }

    section[v="split"][textAlign="left"] .subtitle {
      text-align: left;
    }

    section[v="split"] .below {
      padding: var(--space-sm) var(--space-md) 0;
      height: var(--below-h);
      position: absolute;
      left: 0;
      width: 100%;
      top: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: ${LAYOUT_SWITCH_AT - 1}px) {
    section[v="split"][alternative-order="true"] .subtitle {
      order: 0;
      margin-top: var(--space-xs);
      margin-bottom: 1.5em;
      text-align: center;
      border: none;
      padding-top: 0;
      padding-right: 0;
    }

    section[v="split"][alternative-order="true"] .action {
      margin-top: 0;
      padding-bottom: calc(var(--space-sm) * 2.5);
      border-bottom: 1px solid #00000022;
    }
  }
`;
var variant_split_default = variantSplitStyles;

// src/modules/hero/hero.component.ts
styleInject_default(hero_global_default);
var NineHero = class extends NineElement {
  constructor() {
    super(...arguments);
    this.image = "";
    this.largeImage = "false";
    this.background = "";
    this.flip = "false";
    this.trust = "";
    this.variant = "rising";
    this.textAlign = "";
    this.primarySide = "";
    this.hideOverlayMobile = "false";
    this.centerNarrow = "false";
    this.alternativeOrder = "false";
    this.logoCustomSize = "false";
    this.color = "";
    this.imageOpacity = 0;
    this.logoMarginBottom = "0";
    this.keepImgRatio = false;
    this.handleScroll = () => {
      const scrollPos = getMainScrollPosition().y * 1.5;
      const windowHeight = window.innerHeight;
      const divider = windowHeight < 600 ? 0.7 : 2;
      const percentPastCenter = (scrollPos - windowHeight / divider) / windowHeight;
      const set = 0.5 + Math.min(1, percentPastCenter);
      this.imageOpacity = set;
    };
  }
  firstUpdated() {
    addScrollListener(this.handleScroll, {
      passive: true
    });
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    removeScrollListener(this.handleScroll);
  }
  getVariantStyles() {
    switch (this.variant) {
      case "rising":
        return variant_rising_default;
      case "ball":
        return variant_ball_default;
      case "halfball":
        return variant_halfball_default;
      case "gradient":
        return variant_gradient_default;
      case "split":
        return variant_split_default;
      case "article":
        return variant_article_default;
      default:
        return "";
    }
  }
  render() {
    return html7`<style>
        ${this.getVariantStyles()}
      </style>
      <section
        v="${this.variant}"
        color="${this.color}"
        flip="${this.flip}"
        style="
          --img-o: ${this.imageOpacity};
          --logo-margin-bottom: var(--space-${this.logoMarginBottom});
        "
        textAlign="${this.textAlign}"
        largeImage="${this.largeImage}"
        hideOverlayMobile="${this.hideOverlayMobile}"
        primary-side="${this.primarySide}"
        alternative-order="${this.alternativeOrder}"
        logo-custom-size="${this.logoCustomSize}"
        ?keep-img-ratio=${this.keepImgRatio}
      >
        <div class="img-c" center-narrow="${this.centerNarrow}">
          <div class="wrap">
            <div class="content">
              <div class="logo"><slot name="logo"></slot></div>
              <div class="title"><slot name="title"></slot></div>
              <div class="subtitle"><slot name="subtitle"></slot></div>
              <div class="action"><slot name="action"></slot></div>
              <div class="caption"><slot name="caption"></slot></div>
              <div class="overlay"><slot name="overlay"></slot></div>
              ${this.trust ? html7`<div class="trust">
                    <img src=${this.trust} aria-hidden="true" alt="" />
                  </div>` : ""}
            </div>
          </div>
          ${this.image ? html7`<div class="image">
                <div class="img-crop">
                  <img src=${this.image} aria-hidden="true" alt="" />
                </div>
              </div>` : ""}
        </div>

        <div class="below"><slot name="below"></slot></div>
      </section>`;
  }
};
NineHero.styles = css19`
    section[v="rising"]::after {
      ${bgGradientSunriseLight};
    }
    ${unsafeCSS2(hero_default)}
  `;
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "image", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "largeImage", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "background", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "flip", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "trust", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "variant", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "textAlign", 2);
__decorateClass([
  property6({ type: String, attribute: "primary-side" })
], NineHero.prototype, "primarySide", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "hideOverlayMobile", 2);
__decorateClass([
  property6({ type: String, attribute: "center-narrow" })
], NineHero.prototype, "centerNarrow", 2);
__decorateClass([
  property6({ type: String, attribute: "alternative-order" })
], NineHero.prototype, "alternativeOrder", 2);
__decorateClass([
  property6({ type: String, attribute: "logo-custom-size" })
], NineHero.prototype, "logoCustomSize", 2);
__decorateClass([
  property6({ type: String })
], NineHero.prototype, "color", 2);
__decorateClass([
  property6({ type: Number })
], NineHero.prototype, "imageOpacity", 2);
__decorateClass([
  property6({ type: String, attribute: "logo-margin-bottom" })
], NineHero.prototype, "logoMarginBottom", 2);
__decorateClass([
  property6({ type: Boolean, attribute: "keep-img-ratio" })
], NineHero.prototype, "keepImgRatio", 2);

// src/modules/hero/hero.ts
var hero_default2 = NineHero;
NineHero.define("nine-hero");

// src/modules/hero-bullets/hero-bullets.component.ts
import { html as html8, unsafeCSS as unsafeCSS3 } from "lit";
import { property as property7 } from "lit/decorators.js";

// src/modules/hero-bullets/hero-bullets.scss?inline
import { css as css20 } from "lit-element/lit-element.js";
var hero_bullets_default = css20`:host {
  display: block;
}

section {
  position: relative;
  padding: calc(var(--space-sm) * 6) var(--space-md) 0;
  overflow: hidden;
}
section::before {
  content: "";
  width: clamp(1800px, 160%, 2000px);
  aspect-ratio: 1;
  position: absolute;
  bottom: min(25%, 90vw);
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, 0%);
  background-image: linear-gradient(180deg, #fffdf4 54.42%, #fbdef3 76.94%, #ffdeb8 95.58%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center bottom;
  z-index: -1;
  pointer-events: none;
}
section::after {
  content: "";
  width: 100vw;
  height: min(20%, 20vw);
  background-image: linear-gradient(180deg, var(--color-cream), transparent);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.wrapper {
  display: grid;
  gap: var(--space-sm);
  max-width: var(--page-max-width);
  margin: 0 auto;
}
.wrapper[align-center] {
  align-items: center;
}

::slotted([slot=image]) {
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}

[img-full-height] ::slotted([slot=image]) {
  height: 100%;
  object-fit: cover;
}

::slotted([slot=extra]) {
  margin-top: calc(var(--space-sm) * 1.5);
}

@media screen and (min-width: 960px) {
  section {
    padding: calc(var(--space-sm) * 8) var(--space-md);
    margin-bottom: max(-8em, -10vw);
    padding-bottom: min(8em, 10vw);
  }
  section::before {
    bottom: 0;
  }
  .wrapper {
    grid-template-columns: 1fr 1fr;
    gap: calc(var(--space-sm) * 5.25);
  }
  ::slotted([slot=extra]) {
    margin-top: calc(var(--space-sm) * 1.75);
  }
}`;

// src/modules/hero-bullets/hero-bullets.component.ts
var NineHeroBullets = class extends NineElement {
  constructor() {
    super(...arguments);
    this.alignCenter = false;
    this.imgFullHeight = false;
  }
  render() {
    return html8`
      <section>
        <div class="wrapper" ?align-center=${this.alignCenter}>
          <div>
            <slot name="text"></slot>
            <slot name="extra"></slot>
          </div>
          <div ?img-full-height=${this.imgFullHeight}>
            <slot name="image"></slot>
          </div>
        </div>
      </section>
    `;
  }
};
NineHeroBullets.styles = unsafeCSS3(hero_bullets_default);
__decorateClass([
  property7({ type: Boolean, attribute: "align-center" })
], NineHeroBullets.prototype, "alignCenter", 2);
__decorateClass([
  property7({ type: Boolean, attribute: "img-full-height" })
], NineHeroBullets.prototype, "imgFullHeight", 2);

// src/modules/hero-bullets/hero-bullets.ts
var hero_bullets_default2 = NineHeroBullets;
NineHeroBullets.define("nine-hero-bullets");

// src/modules/image-grid/image-grid.component.ts
import { css as css21, html as html9 } from "lit";
var NineImageGrid = class extends NineElement {
  render() {
    return html9`
      <section>
        <div class="center">
          <div class="text">
            <slot name="text"></slot>
          </div>

          <slot name="grid"></slot>
        </div>
      </section>
    `;
  }
};
NineImageGrid.styles = css21`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
      padding: 0 var(--space-md);
    }

    .center {
      max-width: var(--page-max-width);
      margin: 0 auto;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      max-width: 708px;
      margin-bottom: var(--space-lg);
    }

    ::slotted(.text p) {
      background-color: red;
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      .text {
        margin-bottom: var(--space-xl);
      }
    }
  `;

// src/modules/image-grid/image-grid.ts
var image_grid_default = NineImageGrid;
NineImageGrid.define("nine-image-grid");

// src/modules/investors/investors.component.ts
import { css as css22, html as html10 } from "lit";
import { createRef as createRef2, ref as ref2 } from "lit/directives/ref.js";
var NineInvestors = class extends NineElement {
  constructor() {
    super(...arguments);
    this.elRef = createRef2();
    this.scrollDir = (dir) => () => {
      const scroller = this.elRef.value;
      scroller == null ? void 0 : scroller.dispatchEvent(new CustomEvent("scrollDir", { detail: dir }));
    };
  }
  render() {
    return html10`
      <section>
        <div class="center">
          <div class="text">
            <slot name="text"></slot>
          </div>

          <nine-scrolling fade="false" class="scroller" ${ref2(this.elRef)}>
            <slot name="items" slot="items"></slot>
          </nine-scrolling>

          <div class="buttons">
            <nine-button
              aria-label="Previous"
              round="true"
              variant="outline"
              arrow="->"
              direction="left"
              mr
              @click="${this.scrollDir(-1)}"
            ></nine-button>
            <nine-button
              aria-label="Next"
              round="true"
              variant="outline"
              arrow="->"
              @click="${this.scrollDir(1)}"
            ></nine-button>
          </div>

          <slot name="logos"></slot>
        </div>
      </section>
    `;
  }
};
NineInvestors.styles = css22`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
      --scroller-pad-right: var(--space-md);
      --scroller-pad-left: var(--space-md);
    }

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .center {
      width: min(var(--page-max-width), 100%);
      margin: 0 auto;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      padding: 0 var(--space-md) calc(var(--space-sm) * 3.5);
      max-width: 768px;
    }

    .buttons {
      margin: -45px 0 0;
      display: flex;
      justify-content: center;
    }

    .buttons nine-button:first-child {
      --gradient-pos: 0%;
      --gradient-size: 200%;
    }

    .buttons nine-button:last-child {
      --gradient-pos: 100%;
      --gradient-size: 200%;
    }

    ::slotted(nine-logo-list) {
      margin-top: var(--space-lg);
    }

    @media screen and (min-width: 1200px) {
      .buttons {
        display: none;
      }

      ::slotted(nine-logo-list) {
        margin-top: 0;
      }
    }
  `;

// src/modules/investors/investors.ts
var investors_default = NineInvestors;
NineInvestors.define("nine-investors");

// src/modules/logos/logos.component.ts
import { css as css23, html as html11 } from "lit";
import { property as property8 } from "lit/decorators.js";
var NineLogos = class extends NineElement {
  constructor() {
    super(...arguments);
    this.marginTop = "full";
    this.marginBottom = "full";
  }
  render() {
    return html11`
      <section>
        <div class="center">
          <div class="text"><slot name="text"></slot></div>
          <slot name="logos"></slot>
        </div>
      </section>
    `;
  }
};
NineLogos.styles = css23`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: 0;
    }

    :host([margin-top="full"]) {
      margin-top: var(--section-gap);
    }
    :host([margin-top="half"]) {
      margin-top: calc(var(--section-gap) / 2);
    }

    :host([margin-bottom="full"]) {
      margin-bottom: var(--section-gap);
    }
    :host([margin-bottom="half"]) {
      margin-bottom: calc(var(--section-gap) / 2);
    }

    .center {
      width: min(var(--page-max-width), 100%);
      margin: 0 auto;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      padding: 0 var(--space-md);
      max-width: 680px;
      margin-bottom: calc(var(--space-sm) * 2.5);
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      .text {
        margin-bottom: calc(var(--space-sm) * 3.5);
      }
    }
  `;
__decorateClass([
  property8({ type: String, attribute: "margin-top", reflect: true })
], NineLogos.prototype, "marginTop", 2);
__decorateClass([
  property8({ type: String, attribute: "margin-bottom", reflect: true })
], NineLogos.prototype, "marginBottom", 2);

// src/modules/logos/logos.ts
var logos_default = NineLogos;
NineLogos.define("nine-logos");

// src/modules/pricing/pricing.component.ts
import { css as css24, html as html12 } from "lit";
var NinePricing = class extends NineElement {
  render() {
    return html12`
      <section>
        <div class="center">
          <div class="text"><slot name="text"></slot></div>
          <div class="options"><slot name="options"></slot></div>
          <div class="extras"><slot name="extras"></slot></div>
        </div>
      </section>
    `;
  }
};
NinePricing.styles = css24`
    :host {
      margin: var(--section-gap) 0;
      display: block;
      padding: 0 var(--space-md);
      position: relative;
      z-index: 3;
    }

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text {
      padding: 0 var(--space-md) var(--space-xl);
      text-align: center;
    }

    .center {
      max-width: var(--page-max-width);
      margin: 0 auto;
    }

    .options {
      margin: 0 0 var(--space-xl);
    }

    .extras {
      margin: var(--space-lg) 0 0;
    }
  `;

// src/modules/pricing/pricing.ts
var pricing_default = NinePricing;
NinePricing.define("nine-pricing");

// src/modules/product/product.component.ts
import { css as css26, html as html13, nothing } from "lit";
import { property as property9 } from "lit/decorators.js";

// src/modules/product/product.global.scss?inline
import { css as css25 } from "lit-element/lit-element.js";
var product_global_default = css25`nine-product *[slot=items] nine-item {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: var(--space-sm);
}

@media screen and (min-width: 920px) {
  nine-product *[slot=items] nine-item {
    margin-top: 0;
  }
  nine-product[variant=image-center] *[slot=items] nine-item {
    margin: var(--space-md) 0;
    padding-right: calc(var(--image-w) / 2);
    justify-content: flex-start;
    width: 50%;
    height: 50%;
    display: flex;
  }
  nine-product[variant=image-center] *[slot=items] nine-item:nth-of-type(2n) {
    padding-left: calc(var(--image-w) / 2);
    padding-right: 0;
    justify-content: flex-end;
  }
  nine-product[variant=image-left] *[slot=items] nine-item {
    margin: var(--space-md) 0;
    padding-right: var(--space-lg);
    justify-content: flex-start;
    width: 50%;
    height: 50%;
    display: flex;
  }
  nine-product[variant=image-left] *[slot=items] nine-item:nth-of-type(2n) {
    padding-right: 0;
    padding-left: var(--space-lg);
  }
}
nine-product video {
  object-fit: cover;
  height: 100%;
  width: 100%;
}`;

// src/modules/product/product.component.ts
styleInject_default(product_global_default);
var NineProduct = class extends NineElement {
  constructor() {
    super(...arguments);
    this.image = "";
    this.variant = "image-center";
    this.phoneFrame = false;
  }
  render() {
    return html13`
            <section class="v-${this.variant}">
                <div class="text">
                    <slot name="text"></slot>
                </div>
                <div class="center">
                    <div class="image">
                        ${this.image ? html13`<img src="${this.image}" alt=""/>` : nothing}
                        <slot name="media"></slot>
                    </div>
                    <div class="items">
                        <slot name="items"></slot>
                    </div>
                </div>
                <div class="below">
                    <slot name="below"></slot>
                </div>
            </section>
        `;
  }
};
NineProduct.styles = css26`
      :host {
        display: block;
        position: relative;
        z-index: 2;
        --image-w: 330px;
        margin: var(--section-gap) 0;
      }

      .center {
        margin: 0 auto;
        position: relative;
        width: 100%;
      }

      .text {
        text-align: center;
        padding: 0 var(--space-md);
        margin-bottom: var(--space-md);
      }

      .below {
        text-align: center;
        color: var(--color-charcoal-80);
        margin-top: 2em;
        font-size: 0.9em;
        padding: 0 var(--space-md) 0;
      }

      .items {
        width: min(100%, var(--page-max-width));
        margin: 0 auto;
        padding: 0 var(--space-md);
        box-sizing: border-box;
      }

      .items ::slotted([slot="items"]) {
        display: flex;
        flex-wrap: wrap;
      }

      .image {
        width: min(90%, var(--image-w));
        margin: 0 auto;
        border-radius: 8px;
        position: relative;
      }

      .image img {
        width: 100%;
        height: auto;
      }

      .v-image-left {
        max-width: var(--page-max-width-wide);
        margin: 2em auto 0;
      }

      :host([phone-frame]) .image {
        margin-top: 3rem;
        margin-bottom: 1.5rem;
      }

      :host([phone-frame]) .image:after {
        overflow: hidden;
        content: "";
        position: absolute;
        inset: -10%;
        z-index: 1;
        background: url("https://cdn.sanity.io/images/g38rxyoc/production/288ecf4a05e303ecc6c602aab2c60c5ab49ee39b-2454x3276.png") no-repeat center center;
        background-size: contain;
      }

      :host([phone-frame]) ::slotted([slot="media"]),
      :host([phone-frame]) .image img {
        width: 67%;
        height: auto;
        object-fit: contain;
        aspect-ratio: 6 / 13;
        overflow: hidden;
        transform: translate(24.7%, -0.8%);
      }

      @media screen and (min-width: 920px) {
        :host([phone-frame]) .image:after {
          inset: 0;
        }

        .v-image-center .center {
          margin: var(--space-xl) auto;
        }

        .v-image-center .image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .v-image-left {
          --image-w: min(650px, 40vw);
        }

        .v-image-left .center {
          --content-w: calc(var(--image-w) + var(--space-lg));
          padding-left: var(--content-w);
          width: calc(100% - var(--content-w));
        }

        .v-image-left .image {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }

        .v-image-left .below {
          padding-left: var(--image-w);
        }

        :host([phone-frame]) .image {
          position: absolute;
          height: 100%;
          width: 100%;
          margin: 0;
        }

        :host([phone-frame]) ::slotted([slot="media"]),
        :host([phone-frame]) .image img {
          height: 91%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50.9%);
          object-fit: contain;
          width: auto;
          border-radius: 4%;
          overflow: hidden;
        }
      }

    `;
__decorateClass([
  property9({ type: String })
], NineProduct.prototype, "image", 2);
__decorateClass([
  property9({ type: String, reflect: true })
], NineProduct.prototype, "variant", 2);
__decorateClass([
  property9({ type: Boolean, reflect: true, attribute: "phone-frame" })
], NineProduct.prototype, "phoneFrame", 2);

// src/modules/product/product.ts
var product_default = NineProduct;
NineProduct.define("nine-product");

// src/modules/roi-calculator/roi-calculator.component.ts
import { html as html14, unsafeCSS as unsafeCSS4 } from "lit";

// src/modules/roi-calculator/roi-calculator.scss?inline
import { css as css27 } from "lit-element/lit-element.js";
var roi_calculator_default = css27`:host {
  display: block;
  margin: var(--section-gap) auto;
  padding: 0 var(--space-md);
}

section {
  position: relative;
}

.wrapper {
  display: grid;
  gap: var(--space-sm);
  max-width: var(--page-max-width);
  margin: 0 auto;
}

.leftColumn {
  margin: var(--space-md) 0;
}

p {
  font-weight: 500;
}

::slotted([slot=result]) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(var(--space-md) * 6) var(--space-md) 0;
  width: 100%;
  height: 400px;
  max-width: 600px;
}

::slotted([slot=moreInfo]) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.result {
  margin: 0;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(rgba(128, 174, 255, 0.6), rgba(247, 189, 230, 0.6), rgba(255, 189, 112, 0.6));
}

::slotted([slot=input]) {
  margin-top: calc(var(--space-sm) * 1.5);
}

@media screen and (min-width: 960px) {
  section {
    padding: 0 var(--space-md);
  }
  section::before {
    bottom: 0;
  }
  .result {
    padding: 80px 10px;
  }
  .wrapper {
    grid-template-columns: 1fr 1fr;
    gap: calc(var(--space-sm) * 5.25);
  }
  ::slotted([slot=input]) {
    margin-top: calc(var(--space-sm) * 1.75);
  }
}`;

// src/modules/roi-calculator/roi-calculator.component.ts
import { property as property10 } from "lit/decorators.js";
var NineRoiCalculator = class extends NineElement {
  constructor() {
    super(...arguments);
    this.value = 1500;
    this.isCheckboxChecked = false;
  }
  handleChildCheckedChanged(event) {
    this.isCheckboxChecked = event.detail;
  }
  render() {
    return html14`
      <section>
        <div class="wrapper">
          <div class="leftColumn">
            <slot name="text"></slot>
            <nine-slider-with-input
              max="250000"
              min="1"
              .value=${this.value}
              label="Covered lives"
              .changeHandler=${(e) => {
      this.value = e;
    }}
              @change=${this.handleChildCheckedChanged}
            >
              <slot name="medicationCheckbox" slot="medicationCheckbox"></slot>
            </nine-slider-with-input>
          </div>
          <div class="result">
            <nine-roi-calculator-result covered-lives=${this.value} ?medication-covered=${this.isCheckboxChecked}>
              <slot name="textSavingPerMember" slot="textSavingPerMember"></slot>
              <slot name="textSavingTotal" slot="textSavingTotal"></slot>
            </nine-roi-calculator-result>
            <div>
              <slot name="moreInfo"></slot>
            </div>
          </div>
        </div>
      </section>
    `;
  }
};
NineRoiCalculator.styles = unsafeCSS4(roi_calculator_default);
__decorateClass([
  property10({ type: Number })
], NineRoiCalculator.prototype, "value", 2);
__decorateClass([
  property10({ type: Boolean })
], NineRoiCalculator.prototype, "isCheckboxChecked", 2);

// src/modules/roi-calculator/roi-calculator.ts
var roi_calculator_default2 = NineRoiCalculator;
NineRoiCalculator.define("nine-roi-calculator");

// src/modules/side-step-section/side-step-section.component.ts
import { css as css28, html as html15 } from "lit";
var NineSideStepSection = class extends NineElement {
  render() {
    return html15`
      <section>
        <div class="center">
          <div class="text"><slot name="text"></slot></div>
          <slot name="rows"></slot>
        </div>
      </section>
    `;
  }
};
NineSideStepSection.styles = css28`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
      padding: 0 var(--space-md);
    }

    .center {
      width: min(var(--page-max-width), 100%);
      margin: 0 auto;
    }

    .text {
      max-width: var(--text-max-width, 526px);
      margin-bottom: calc(var(--space-sm) * 2.5);
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      .text {
        text-align: center;
        margin: 0 auto;
        margin-bottom: calc(var(--space-sm) * 3.5);
      }
    }
  `;

// src/modules/side-step-section/side-step-section.ts
var side_step_section_default = NineSideStepSection;
NineSideStepSection.define("nine-side-step-section");

// src/modules/social-proof/social-proof.component.ts
import { css as css29, html as html17 } from "lit";
import { property as property11 } from "lit/decorators.js";

// src/constants/icons.ts
import { html as html16 } from "lit";
var UploadPictureIcon = html16`
  <svg
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M637.5 663.542v5.208h-275v-5.208c0-23.611 11.979-41.493 35.938-53.646 23.958-12.153 57.812-18.229 101.562-18.229s77.604 6.076 101.562 18.229c23.959 12.153 35.938 30.035 35.938 53.646Zm-95.312-169.271c-11.459 11.458-25.521 17.187-42.188 17.187s-30.729-5.729-42.187-17.187c-11.459-11.458-17.188-25.521-17.188-42.188 0-16.666 5.729-30.729 17.188-42.187 11.458-11.458 25.52-17.188 42.187-17.188 16.667 0 30.729 5.73 42.188 17.188 11.458 11.458 17.187 25.521 17.187 42.187 0 16.667-5.729 30.73-17.187 42.188ZM141.667 816.667c11.111 11.111 24.652 16.666 40.625 16.666h635.416c15.973 0 29.514-5.555 40.625-16.666S875 792.014 875 776.042V311.458c0-15.972-5.556-29.687-16.667-41.145-11.111-11.459-24.652-17.188-40.625-17.188H684.375l-77.083-86.458H392.708l-77.083 86.458H182.292c-15.973 0-29.514 5.729-40.625 17.188C130.556 281.771 125 295.486 125 311.458v464.584c0 15.972 5.556 29.514 16.667 40.625ZM779.2 285.417H669.792l-77.084-86.459H407.292l-77.084 86.459H182.292c-6.945 0-12.848 2.604-17.709 7.812-4.861 5.209-7.291 11.285-7.291 18.229v464.584c0 6.944 2.43 12.847 7.291 17.708 4.861 4.861 10.764 7.292 17.709 7.292h360.949C712.221 671.631 768.417 356.854 779.2 285.417Z"
      fill="#212121"
    />
  </svg>
`;
var UploadIDIcon = html16`
  <svg
    width="1000"
    height="1000"
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M783.333 104.167H937.5v155.208h-32.292V136.458H783.333v-32.291Zm0 759.375h121.875V741.667H937.5v154.166H783.333v-32.291ZM62.5 104.167h155.208v32.291H94.792v122.917H62.5V104.167Zm0 637.5h32.292v121.875h122.916v32.291H62.5V741.667Z"
      fill="#212121"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M183.786 724.292c9.805 9.805 21.755 14.708 35.85 14.708h560.729c14.095 0 26.045-4.903 35.85-14.708s14.708-21.755 14.708-35.85V311.558c0-14.094-4.903-26.044-14.708-35.849-9.805-9.806-21.755-14.708-35.85-14.708H219.636c-14.095 0-26.045 4.902-35.85 14.708-9.805 9.805-14.708 21.755-14.708 35.849v376.884c0 14.095 4.903 26.045 14.708 35.85Zm391.476-13.788H219.636c-6.128 0-11.337-2.145-15.627-6.435-4.29-4.29-6.435-9.499-6.435-15.627V311.558c0-6.128 2.145-11.337 6.435-15.626 4.29-4.29 9.499-6.435 15.627-6.435h533.053c-3.126 21.904-12.008 77.071-30.133 142.48H576.297v28.496h137.945c-5.627 18.334-11.965 37.177-19.083 56.073H576.297v28.497h107.522c-26.337 62.644-61.68 123.364-108.557 165.461Zm-90.888-105.712H287.659v-3.677c0-8.579 2.145-16.392 6.434-23.44 4.29-7.047 10.418-12.103 18.385-15.167 13.482-4.903 26.504-8.58 39.067-11.031 12.563-2.451 24.36-3.677 35.39-3.677 11.031 0 22.215 1.073 33.552 3.217 11.337 2.145 24.666 5.976 39.987 11.491 7.354 3.064 13.175 8.12 17.465 15.167 4.29 7.048 6.435 14.861 6.435 23.44v3.677Zm-68.023-100.655c-7.967 8.273-17.772 12.409-29.416 12.409-11.03 0-20.682-4.136-28.955-12.409s-12.41-17.619-12.41-28.037c0-11.643 4.137-21.601 12.41-29.875 8.273-8.273 17.925-12.409 28.955-12.409 11.644 0 21.449 4.136 29.416 12.409 7.966 8.274 11.95 17.925 11.95 28.956 0 11.031-3.984 20.683-11.95 28.956Z"
      fill="#212121"
    />
  </svg>
`;
var LockIcon = html16`
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.11321 21.125C6.64654 21.125 6.24654 20.9583 5.91321 20.625C5.57987 20.2917 5.41321 19.8917 5.41321 19.425V10.15C5.41321 9.66667 5.57987 9.2625 5.91321 8.9375C6.24654 8.6125 6.64654 8.45 7.11321 8.45H8.56321V6.25C8.56321 5.06667 8.97154 4.0625 9.78821 3.2375C10.6049 2.4125 11.6049 2 12.7882 2C13.9715 2 14.9715 2.4125 15.7882 3.2375C16.6049 4.0625 17.0132 5.06667 17.0132 6.25V8.45H18.4632C18.9299 8.45 19.3299 8.6125 19.6632 8.9375C19.9965 9.2625 20.1632 9.66667 20.1632 10.15V19.425C20.1632 19.8917 19.9965 20.2917 19.6632 20.625C19.3299 20.9583 18.9299 21.125 18.4632 21.125H7.11321ZM7.11321 20.025H18.4632C18.6299 20.025 18.7715 19.9667 18.8882 19.85C19.0049 19.7333 19.0632 19.5917 19.0632 19.425V10.15C19.0632 9.98333 19.0049 9.84167 18.8882 9.725C18.7715 9.60833 18.6299 9.55 18.4632 9.55H7.11321C6.94654 9.55 6.80487 9.60833 6.68821 9.725C6.57154 9.84167 6.51321 9.98333 6.51321 10.15V19.425C6.51321 19.5917 6.57154 19.7333 6.68821 19.85C6.80487 19.9667 6.94654 20.025 7.11321 20.025ZM12.7882 16.45C13.2382 16.45 13.6257 16.2917 13.9507 15.975C14.2757 15.6583 14.4382 15.275 14.4382 14.825C14.4382 14.3917 14.2757 14.0083 13.9507 13.675C13.6257 13.3417 13.2382 13.175 12.7882 13.175C12.3382 13.175 11.9507 13.3417 11.6257 13.675C11.3007 14.0083 11.1382 14.4 11.1382 14.85C11.1382 15.2833 11.3007 15.6583 11.6257 15.975C11.9507 16.2917 12.3382 16.45 12.7882 16.45ZM9.63821 8.45H15.9382V6.25C15.9382 5.36667 15.6299 4.62083 15.0132 4.0125C14.3965 3.40417 13.6549 3.1 12.7882 3.1C11.9215 3.1 11.1799 3.40417 10.5632 4.0125C9.94654 4.62083 9.63821 5.36667 9.63821 6.25V8.45ZM6.51321 20.025V9.55V20.025Z"
      fill="#212121"
    />
  </svg>
`;
var CheckSmallWhiteIcon = html16`
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 4.58509L5.90746 12L2 8.41973L2.64052 7.83464L5.90746 10.828L13.3595 4L14 4.58509Z"
      fill="#fff"
    />
  </svg>
`;
var CheckSmallIcon = html16`<svg
  width="16"
  height="16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14 4.585 5.907 12 2 8.42l.64-.585 3.267 2.993L13.36 4l.64.585Z"
    fill="#212121"
  />
</svg>`;
var CaretDownIcon = html16`<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="m12 10.36-5.29 4.7-.66-.75 5.94-5.29 5.95 5.29-.67.74L12 10.36Z"
    fill="#212121"
  />
</svg>`;
var EyeIcon = html16`<svg
  width="25"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.771 15.725a3.69 3.69 0 0 0 2.725-1.137c.75-.759 1.125-1.663 1.125-2.713a3.69 3.69 0 0 0-1.137-2.725c-.759-.75-1.663-1.125-2.713-1.125-1.067 0-1.975.38-2.725 1.137-.75.759-1.125 1.663-1.125 2.713A3.69 3.69 0 0 0 10.06 14.6c.758.75 1.662 1.125 2.712 1.125Zm0-1.075c-.767 0-1.42-.275-1.962-.825-.542-.55-.813-1.2-.813-1.95 0-.767.275-1.42.825-1.963.55-.541 1.2-.812 1.95-.812.767 0 1.421.275 1.963.825.541.55.812 1.2.812 1.95 0 .767-.275 1.42-.825 1.963-.55.541-1.2.812-1.95.812Zm0 4.1c-2.25 0-4.308-.625-6.175-1.875s-3.267-2.917-4.2-5c.933-2.083 2.333-3.75 4.2-5A10.863 10.863 0 0 1 12.771 5c2.25 0 4.308.625 6.175 1.875s3.267 2.917 4.2 5c-.933 2.083-2.333 3.75-4.2 5a10.863 10.863 0 0 1-6.175 1.875Zm0-1.1c1.983 0 3.792-.517 5.425-1.55s2.892-2.442 3.775-4.225c-.883-1.783-2.142-3.192-3.775-4.225-1.633-1.033-3.442-1.55-5.425-1.55-1.983 0-3.796.517-5.437 1.55-1.642 1.033-2.896 2.442-3.763 4.225.867 1.783 2.121 3.192 3.763 4.225 1.641 1.033 3.454 1.55 5.437 1.55Z"
    fill="#212121"
  />
</svg>`;
var EyeClosedIcon = html16`<svg
  width="25"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="m16.296 12.925-.825-.85c.25-1.1-.02-1.987-.812-2.662-.792-.675-1.63-.896-2.513-.663l-.85-.825c.167-.083.384-.158.65-.225.267-.067.542-.1.825-.1 1.067 0 1.975.375 2.725 1.125s1.125 1.658 1.125 2.725c0 .283-.029.558-.087.825a2.17 2.17 0 0 1-.238.65Zm3.225 3.175-.775-.75a12.372 12.372 0 0 0 1.913-1.813 8.328 8.328 0 0 0 1.312-2.087c-.85-1.783-2.091-3.192-3.725-4.225a9.855 9.855 0 0 0-5.375-1.55c-.566 0-1.154.05-1.762.15A8.84 8.84 0 0 0 9.57 6.2l-.85-.875c.55-.217 1.196-.396 1.938-.538.741-.141 1.47-.212 2.187-.212 2.2 0 4.246.62 6.138 1.862 1.891 1.242 3.279 2.913 4.162 5.013a10.65 10.65 0 0 1-1.487 2.525A13.223 13.223 0 0 1 19.52 16.1Zm1.35 5.45-4.05-4.025c-.433.217-1.008.404-1.725.563-.716.158-1.492.237-2.325.237-2.25 0-4.317-.62-6.2-1.863-1.883-1.241-3.275-2.912-4.175-5.012.367-.9.875-1.75 1.525-2.55.65-.8 1.375-1.517 2.175-2.15L3.071 3.775 3.846 3l17.75 17.75-.725.8Zm-14-13.975c-.6.433-1.225 1.004-1.875 1.712-.65.709-1.125 1.43-1.425 2.163.85 1.783 2.1 3.192 3.75 4.225 1.65 1.033 3.492 1.55 5.525 1.55.633 0 1.263-.058 1.888-.175.625-.117 1.046-.233 1.262-.35l-1.7-1.725a5.859 5.859 0 0 1-.687.213c-.292.074-.571.112-.838.112-1.067 0-1.975-.37-2.725-1.113-.75-.741-1.125-1.654-1.125-2.737 0-.233.038-.496.113-.787.075-.292.145-.538.212-.738l-2.375-2.35Z"
    fill="#212121"
  />
</svg>`;
var ErrorIcon = html16`<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Zm0 .96a10.96 10.96 0 1 0 0-21.92 10.96 10.96 0 0 0 0 21.92Z"
    fill="#BF1902"
  />
  <path
    d="M11.59 14.07h.92l.42-4.2V6.69h-1.76v3.2l.42 4.19Zm-.52 3.21h1.96v-1.95h-1.96v1.95Z"
    fill="#BF1902"
  />
</svg>`;
var BulletIcon = html16`<svg
  width="16"
  height="16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="8" cy="8" r="2" fill="#212121" />
</svg>`;
var BulletErrorIcon = html16`<svg
  width="16"
  height="16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="8" cy="8" r="2" fill="#BF1902" />
</svg>`;
var PlusIcon = html16`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="14"
  viewBox="0 0 14 14"
  fill="none"
>
  <path
    fill="currentColor"
    fill-opacity=".8"
    d="M13.742 6.497H7.594V.347H6.496v6.15H.348v1.097h6.148v6.148h1.098V7.594h6.148V6.497Z"
  />
</svg>`;

// src/modules/social-proof/social-proof.component.ts
var NineSocialProof = class extends NineElement {
  constructor() {
    super(...arguments);
    this.imageSrc = "";
    this.imageAlt = "";
    this.href = "#";
    this.target = "_self";
    this.rel = "";
    this.upperText = "Secure 256 BIT SSL Encryption";
    this.lowerText = "9amHealth is a BBB\xAE Accredited Businesses You Can Trust";
  }
  render() {
    return html17`
      <section>
        <div class="center">
          <div class="flex flex-col">
            <div gap0 class="flex items-center">
              ${LockIcon}
              <small class="as-tiny color-c-60">${this.upperText}</small>
            </div>
            <div>
              <a href="${this.href}" target="${this.target}" rel="${this.rel}">
                <img src="${this.imageSrc}" alt="${this.imageAlt}" />
              </a>
            </div>
            <div>
              <small class="as-tiny color-b-40">${this.lowerText}</small>
            </div>
          </div>
        </div>
      </section>
    `;
  }
};
NineSocialProof.styles = css29`
    :host {
      display: block;
      margin: var(--section-gap-sm) 0;
    }

    .center {
      max-width: 220px;
      margin: 0 auto;
      padding: 0 var(--space-md);
    }

    .flex {
      display: flex;
      grid-gap: var(--space-lg);
      justify-content: center;
      text-align: center;
    }

    .flex[gap0] {
      grid-gap: 0;
    }

    .flex-col {
      flex-direction: column;
    }

    .items-center {
      align-items: center;
    }

    .as-tiny {
      font-weight: 400;
      /* font-size: 12px; */
      font-size: calc(var(--sm-scale) * 0.75);
      line-height: 140%;
    }

    .color-c-60 {
      color: var(--color-charcoal-60);
    }

    .color-b-40 {
      color: var(--color-black-40);
    }

    img {
      height: 40px;
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
    }
  `;
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "imageSrc", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "imageAlt", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "href", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "target", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "rel", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "upperText", 2);
__decorateClass([
  property11({ type: String })
], NineSocialProof.prototype, "lowerText", 2);

// src/modules/social-proof/social-proof.ts
var social_proof_default = NineSocialProof;
NineSocialProof.define("nine-social-proof");

// src/modules/stat-items/stat-items.component.ts
import { css as css31, html as html18 } from "lit";
import { property as property12 } from "lit/decorators.js";

// src/modules/stat-items/stat-items.global.scss?inline
import { css as css30 } from "lit-element/lit-element.js";
var stat_items_global_default = css30`nine-stat-items[background] nine-stat-item {
  max-width: 100%;
  --icon-gradient: url(#dusk_vertical_gradient);
}
nine-stat-items[background] nine-stat-item [slot=value],
nine-stat-items[background] nine-stat-item [slot=unit] {
  line-height: 1;
  --gradient-dir: 0deg;
  background-image: var(--wcl-bg-gradient-dusk, linear-gradient(var(--gradient-dir), #0e3763 0%, #5b4855 52.6%, #8a6947 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`;

// src/modules/stat-items/stat-items.component.ts
styleInject_default(stat_items_global_default);
var NineStatItems = class extends NineElement {
  constructor() {
    super(...arguments);
    this.background = false;
  }
  render() {
    return html18`
      <section ?background=${this.background}>
        <div class="center">
          <slot></slot>
        </div>
      </section>
    `;
  }
};
NineStatItems.styles = css31`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
    }

    .center {
      width: min(var(--page-max-width), 100%);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    section[background] {
      border-radius: 0.5rem;
      --gradient-dir: 181deg;
      ${bgGradientSunriseLightest};
      width: calc(100vw - var(--space-md) * 2);
      margin: 0 auto;
    }

    section[background] ::slotted(:not(:last-child)) {
      border-bottom: 1px solid var(--color-cream);
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      .center {
        flex-direction: row;
        align-items: flex-start;
      }

      ::slotted(:not(:last-of-type)) {
        border-right: 1px solid var(--color-charcoal-60);
      }

      section[background] {
        border-radius: 0;
        --gradient-dir: 90deg;
        ${bgGradientSunriseLightest};
        width: 100vw;
        margin: 0;
        padding: var(--space-lg) 0;
      }

      section[background] ::slotted(:not(:last-child)) {
        border-bottom: none;
        border-right: 1px solid var(--color-cream);
      }

      section[background] .center {
        width: min(var(--page-max-width-wide), 100%);
      }
    }
  `;
__decorateClass([
  property12({ type: Boolean })
], NineStatItems.prototype, "background", 2);

// src/modules/stat-items/stat-items.ts
var stat_items_default = NineStatItems;
NineStatItems.define("nine-stat-items");

// src/modules/statistics-section/statistics-section.component.ts
import { css as css32, html as html19 } from "lit";
var NineStatisticsSection = class extends NineElement {
  render() {
    return html19`
      <section>
        <div class="center">
          <div class="text"><slot name="text"></slot></div>
          <slot name="content"></slot>
        </div>
      </section>
    `;
  }
};
NineStatisticsSection.styles = css32`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
    }

    .center {
      width: min(var(--page-max-width), 100%);
      margin: 0 auto;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      padding: 0 var(--space-md);
      max-width: var(--text-max-width, 680px);
      margin-bottom: var(--space-lg);
    }
  `;

// src/modules/statistics-section/statistics-section.ts
var statistics_section_default = NineStatisticsSection;
NineStatisticsSection.define("nine-statistics-section");

// src/modules/steps-list/step-list-step.component.ts
import { css as css34, html as html20 } from "lit";
import { property as property13 } from "lit/decorators.js";
import { createRef as createRef3, ref as ref3 } from "lit/directives/ref.js";

// src/modules/steps-list/step-list-step.global.scss?inline
import { css as css33 } from "lit-element/lit-element.js";
var step_list_step_global_default = css33`nine-steps-list-step img {
  max-width: 100%;
}

nine-steps-list-step *[slot=dynamic] {
  max-width: 323px;
}

nine-steps-list-step *[slot=dynamic] > img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: var(--light-shadow);
  height: auto !important;
}`;

// src/modules/steps-list/step-list-step.component.ts
styleInject_default(step_list_step_global_default);
var NineStepsListStep = class extends NineElement {
  constructor() {
    super(...arguments);
    this.titleRef = createRef3();
    this.textRef = createRef3();
    this.dynamicRef = createRef3();
    this.image = "";
  }
  firstUpdated() {
    const titleEl = this.titleRef.value;
    const textEl = this.textRef.value;
    const dynamicEl = this.dynamicRef.value;
    this.dispatchEvent(
      new CustomEvent("step-loaded", {
        detail: {
          title: titleEl,
          text: textEl,
          dynamic: dynamicEl,
          root: this,
          image: this.image
        },
        bubbles: true
      })
    );
  }
  render() {
    return html20`
            <aside>
                <div class="title" ${ref3(this.titleRef)}>
                    <slot name="title"></slot>
                </div>
                <div class="text" ${ref3(this.textRef)}>
                    <slot name="text"></slot>
                </div>
                <div class="image">
                    <img src="${this.image}"/>
                </div>
                <div class="dynamic" ${ref3(this.dynamicRef)}>
                    <slot name="dynamic"></slot>
                </div>
            </aside>
        `;
  }
};
NineStepsListStep.styles = css34`
      :host {
        display: block;
        text-align: center;
        padding: 0;
        max-width: 100vw;
        box-sizing: border-box;
        padding: 0 var(--space-md);
      }

      :host(:not(:last-of-type)) {
        margin-bottom: calc(var(--space-md) * 2);
      }

      ::slotted(img) {
        max-width: 100%;
        display: block;
        margin: 0 auto;
      }

      .title {
        text-align: center;
      }

      .image {
        position: relative;
        border-radius: 50%;
        width: 100%;
        max-width: 590px;
        margin: var(--space-md) auto 0;
        overflow: hidden;
        aspect-ratio: 1;
      }

      .image img {
        position: absolute;
        width: 100%;
        height: auto;
        display: block;
      }

      .dynamic {
        position: relative;
        max-width: 100%;
        margin: -2em auto 0;
        max-width: 323px;
      }

      .dynamic img {
        max-width: 100%;
      }

      @media screen and (min-width: 1020px) {
        /* Switch layout for desktop */
        aside {
          display: flex;
          position: relative;
          height: var(--item-height);
          opacity: var(--desktop-aside-opacity, 0);
          pointer-events: none;
        }

        aside .image {
          width: auto;
          max-height: 200px;
        }
      }
    `;
__decorateClass([
  property13({ type: String })
], NineStepsListStep.prototype, "image", 2);

// src/modules/steps-list/step-list-step.ts
var step_list_step_default = NineStepsListStep;
NineStepsListStep.define("nine-steps-list-step");

// src/modules/steps-list/steps-list.component.ts
import { css as css35, html as html21 } from "lit";
import { property as property14 } from "lit/decorators.js";
import { createRef as createRef4, ref as ref4 } from "lit/directives/ref.js";

// src/lib/parseHTML.ts
var parseHTML = (html25) => {
  if (!html25) return document.createDocumentFragment();
  return document.createRange().createContextualFragment(html25);
};
var getHtmlFromSlot = (el) => {
  var _a2;
  if (!el) return "";
  const node = (_a2 = el.querySelector("slot")) == null ? void 0 : _a2.assignedNodes()[0];
  if (!node) return "";
  return node.innerHTML;
};
var getNodeContent = (el) => {
  return parseHTML(getHtmlFromSlot(el));
};

// src/modules/steps-list/steps-list.component.ts
var itemScreenHeight = 1;
var NineStepsList = class extends NineElement {
  constructor() {
    super();
    this.steps = [];
    this.activeStep = void 0;
    this.stepsContainerRef = createRef4();
    this.aniRef = createRef4();
    this.live = true;
    this.handleScroll = () => {
      if (!this.live) {
        return;
      }
      const stepsContainer = this.stepsContainerRef.value;
      const aniContainer = this.aniRef.value;
      const itemHeight = window.innerHeight;
      if (!stepsContainer || !aniContainer) return;
      if (window.innerWidth <= 1020) {
        aniContainer.style.position = "relative";
        return;
      }
      const scrollLength = stepsContainer.offsetHeight - itemHeight;
      const { top, bottom } = stepsContainer.getBoundingClientRect();
      const containerFromTop = scrollLength - (scrollLength + top);
      let scrollPercent = containerFromTop / scrollLength;
      if (scrollPercent < 0) {
        scrollPercent = 0;
      }
      if (scrollPercent > 1) {
        scrollPercent = 1;
      }
      const scrolledBelow = bottom <= itemHeight;
      if (scrollPercent >= 1 || scrollPercent <= 0) {
        aniContainer.style.position = "absolute";
        aniContainer.style.top = scrolledBelow ? "auto" : "0";
        aniContainer.style.bottom = !scrolledBelow ? "auto" : "0";
      } else {
        aniContainer.style.position = "fixed";
      }
      stepsContainer.style.setProperty("--sp", `${scrollPercent}`);
      let activeStep = this.steps[0];
      const stepCount = this.steps.length;
      const stepPercent = scrollPercent * stepCount;
      if (stepCount > 1) {
        activeStep = this.steps[Math.floor(stepPercent)];
        if (!activeStep) return;
      }
      if (this.activeStep !== activeStep) this.activeStep = activeStep;
    };
    this.addEventListener(
      "step-loaded",
      this.handleStepLoaded
    );
    if (document.body.hasAttribute("data-hs-dragdrop") || document.querySelector("*[data-hs-dragdrop]")) {
      this.live = false;
    }
    addScrollListener(this.handleScroll);
    window.addEventListener("resize", this.handleScroll, { passive: true });
    this.handleScroll();
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    removeScrollListener(this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  }
  handleStepLoaded(event) {
    if (event == null ? void 0 : event.detail) {
      this.steps = [...this.steps, event.detail];
    }
  }
  render() {
    return html21`
      <section class="${this.live ? "live" : ""}">
        <div class="text"><slot name="text"></slot></div>
        <div class="steps" ${ref4(this.stepsContainerRef)}>
          <div class="ani" ${ref4(this.aniRef)}>
            <div class="center">
              <div class="textItems">
                ${this.steps.map(
      (step) => html21`<div
                      class="${this.activeStep === step ? "active" : ""}"
                    >
                      <h4 class="stepTitle">${getNodeContent(step.title)}</h4>
                      <p class="details">${getNodeContent(step.text)}</p>
                    </div>`
    )}
              </div>
              <div class="images">
                ${this.steps.map(
      (step) => html21`<img
                      class="${this.activeStep === step ? "active" : ""}"
                      src="${step.image}"
                    />`
    )}
              </div>
              <div class="dynamic">
                ${this.steps.map(
      (step) => html21`<div
                      class="dynamo ${this.activeStep === step ? "active" : ""}"
                    >
                      ${getNodeContent(step.dynamic)}
                    </div>`
    )}
              </div>
            </div>
          </div>
          <slot name="steps"></slot>
        </div>
      </section>
    `;
  }
};
NineStepsList.styles = css35`
    :host {
      --item-height: calc(100vh * ${itemScreenHeight});
      display: block;
      position: relative;
      z-index: 5;
      max-width: var(--content-width);
      margin: 0 auto;
      min-height: 100vh;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 var(--space-xl);
      max-width: 100vw;
    }

    .text {
      text-align: center;
      margin: 0 auto;
      padding: var(--space-xl) var(--space-md);
      max-width: 680px;
    }

    .steps {
      position: relative;
    }

    .ani {
      display: none;
    }

    @media screen and (min-width: 1020px) {
      .ani {
        position: absolute;
        height: 100vh;
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }

      .live .ani {
        display: block;
      }

      :host(:not([live])) {
        --desktop-aside-opacity: 1;
      }

      .images {
        --size: 50%;
        width: var(--size);
        aspect-ratio: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        overflow: hidden;
        background-image: var(--gradient-sunrise-light);
      }

      .images img {
        transition: opacity 0.4s ease-in-out;
        opacity: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
      }

      .images img.active {
        opacity: 1;
      }

      .textItems {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 234px;
        transform: translateY(calc(calc(var(--sp) - 0.5) * -25vh));
        position: absolute;
        left: 1rem;
        top: 0;
        bottom: 0;
      }

      .center {
        position: relative;
        margin: 0 auto;
        max-width: var(--page-max-width);
        height: 100vh;
      }

      .stepTitle {
        margin-bottom: 0;
        transition: all 0.4s;
        /* --scale: var(--sm-scale); */
        font-size: calc(var(--sm-scale) * 1.25);
        line-height: 140%;
        letter-spacing: -0.02em;
        font-weight: 500;
        transition: all 0.4s;
        opacity: 0.3;
      }

      .details {
        position: absolute;
        transform: translateY(-9rem);
        margin: 5rem 0 3rem;
        opacity: 0;
        transition: all 0.1s;
        transition-delay: 0;
        font-weight: 400;
      }

      .dynamic {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-220px);
        width: min(100%, 460px);
      }

      .dynamo {
        position: absolute;
        right: 0;
        opacity: 0;
        transform: translateY(2vh);
        transition: all 0.8s;
        z-index: 1;
        width: min(400px, 100%);
      }

      .dynamo > img {
        max-width: 100% !important;
        height: auto !important;
        max-width: 100%;
        border-radius: 8px;
        box-shadow: var(--light-shadow);
        height: auto !important;
      }

      .dynamo.active {
        transform: translateY(0);
        opacity: 1;
        z-index: 2;
      }

      .active .stepTitle {
        margin-bottom: 5rem;
        opacity: 1;
      }

      .active .details {
        opacity: 1;
        transition: all 0.4s;
        transition-delay: 0.2s;
        height: 4rem;
        overflow: hidden;
      }
    }
  `;
__decorateClass([
  property14()
], NineStepsList.prototype, "steps", 2);
__decorateClass([
  property14()
], NineStepsList.prototype, "activeStep", 2);
__decorateClass([
  property14({ type: Boolean, reflect: true })
], NineStepsList.prototype, "live", 2);

// src/modules/steps-list/steps-list.ts
var steps_list_default = NineStepsList;
NineStepsList.define("nine-steps-list");

// src/modules/team/team.component.ts
import { css as css36, html as html22 } from "lit";
import { property as property15 } from "lit/decorators.js";
var NineTeam = class extends NineElement {
  constructor() {
    super(...arguments);
    this.textAlignMobile = "center";
    this.hideButtonsMobile = false;
    this.scrollDir = (dir) => () => {
      var _a2;
      const scroller = (_a2 = this.shadowRoot) == null ? void 0 : _a2.querySelector(".scroller");
      scroller == null ? void 0 : scroller.dispatchEvent(new CustomEvent("scrollDir", { detail: dir }));
    };
  }
  render() {
    return html22`
      <section>
        <div class="center">
          <div class="text" text-align-mobile="${this.textAlignMobile}">
            <slot name="text"></slot>

            <div class="buttons" hide-mobile=${this.hideButtonsMobile}>
              <nine-button
                aria-label="Previous"
                round="true"
                variant="outline"
                arrow="->"
                direction="left"
                mr
                @click="${this.scrollDir(-1)}"
              ></nine-button>
              <nine-button
                aria-label="Next"
                round="true"
                variant="outline"
                arrow="->"
                @click="${this.scrollDir(1)}"
              ></nine-button>
            </div>
          </div>
        </div>

        <nine-scrolling class="scroller">
          <slot name="items" slot="items"></slot>
        </nine-scrolling>
      </section>
    `;
  }
};
NineTeam.styles = css36`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
    }

    :host([p0]) .text {
      padding: 0 var(--space-md);
    }

    .text {
      padding: var(--space-md);
      text-align: center;
    }

    .text[text-align-mobile="left"] {
      text-align: left;
    }

    .text[text-align-mobile="right"] {
      text-align: right;
    }

    .text[text-align-mobile="justify"] {
      text-align: justify;
    }

    .center {
      max-width: var(--page-max-width);
      margin: 0 auto;
    }

    .buttons {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
    }

    .buttons[hide-mobile="true"] {
      display: none;
    }

    .buttons nine-button:first-child {
      --gradient-pos: 0%;
      --gradient-size: 200%;
    }

    .buttons nine-button:last-child {
      --gradient-pos: 100%;
      --gradient-size: 200%;
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      :host([p0]) {
        padding: 0 var(--space-md);
      }

      :host([p0]) .text {
        padding: 5px 0 0;
      }

      .scroller {
        --w: calc(var(--page-max-width) * 0.5);
        top: 0;
        position: absolute;
        left: 50vw;
        transform: translateX(calc(var(--w) * -1));
        width: calc(50vw + var(--w));
        right: 0;
        --scroller-pad-right: 200px;
        --scroller-pad-left: 450px;
      }

      .text {
        margin: var(--space-md) 0 0;
        padding: var(--space-xl);
        width: 40%;
        position: relative;
        z-index: 2;
        max-width: 21rem;
        min-height: 26rem;
        text-align: left;
      }

      .buttons {
        position: static;
        justify-content: flex-start;
        margin-top: var(--space-lg);
      }

      .buttons[hide-mobile="true"] {
        display: flex;
      }
    }
  `;
__decorateClass([
  property15({ type: String, attribute: "text-align-mobile" })
], NineTeam.prototype, "textAlignMobile", 2);
__decorateClass([
  property15({ type: Boolean, attribute: "hide-buttons-mobile" })
], NineTeam.prototype, "hideButtonsMobile", 2);

// src/modules/team/team.ts
var team_default = NineTeam;
NineTeam.define("nine-team");

// src/modules/testimonials/testimonials.component.ts
import { css as css38, html as html23 } from "lit";
import { property as property16 } from "lit/decorators.js";

// src/modules/testimonials/testimonials.global.scss?inline
import { css as css37 } from "lit-element/lit-element.js";
var testimonials_global_default = css37`nine-testimonials [slot=logo] img {
  width: 100%;
}`;

// src/modules/testimonials/testimonials.component.ts
styleInject_default(testimonials_global_default);
var NineTestimonials = class extends NineElement {
  constructor() {
    super(...arguments);
    this.logoCustomSize = "false";
    this.logoMarginBottom = "sm";
    this.scrollDir = (dir) => () => {
      var _a2;
      const scroller = (_a2 = this.shadowRoot) == null ? void 0 : _a2.querySelector(".scroller");
      scroller == null ? void 0 : scroller.dispatchEvent(new CustomEvent("scrollDir", { detail: dir }));
    };
  }
  render() {
    return html23`
      <section
        logo-custom-size="${this.logoCustomSize}"
        style="--logo-margin-bottom: var(--space-${this.logoMarginBottom});"
      >
        <div class="center">
          <slot name="logo"></slot>
          <div class="text">
            <slot name="text"></slot>
          </div>
        </div>
        <nine-scrolling class="scroller">
          <slot name="items" slot="items"></slot>
        </nine-scrolling>
        <div class="buttons">
          <nine-button
            aria-label="Previous"
            round="true"
            variant="outline"
            arrow="->"
            direction="left"
            mr
            @click="${this.scrollDir(-1)}"
          ></nine-button>
          <nine-button
            aria-label="Next"
            round="true"
            variant="outline"
            arrow="->"
            @click="${this.scrollDir(1)}"
          ></nine-button>
        </div>
      </section>
    `;
  }
};
NineTestimonials.styles = css38`
    :host {
      display: block;
      position: relative;
      z-index: 2;
      margin: var(--section-gap) 0;
      --scroller-pad-right: var(--space-md);
      --scroller-pad-left: var(--space-md);
    }

    section {
      display: flex;
      /* min-height: 70vh; */
      flex-direction: column;
      justify-content: center;
    }

    .text {
      padding: var(--space-md);
      text-align: center;
    }

    .buttons {
      margin: -25px 0 0;
      display: flex;
      justify-content: center;
    }
    .buttons nine-button:first-child {
      --gradient-pos: 0%;
      --gradient-size: 200%;
    }
    .buttons nine-button:last-child {
      --gradient-pos: 100%;
      --gradient-size: 200%;
    }

    ::slotted([slot="logo"]) {
      display: flex;
      max-width: 200px;
      margin: 0 auto;
      box-shadow: var(--light-shadow);
      border-radius: 0.5rem;
      margin-bottom: var(--logo-margin-bottom, 0);
      overflow: hidden;
    }

    section[logo-custom-size="true"] ::slotted([slot="logo"]) {
      max-width: 100%;
      width: var(--width, 100%);
      height: var(--height, auto);
    }

    @media screen and (min-width: ${LAYOUT_SWITCH_AT}px) {
      .text {
        margin: var(--space-md) 0 0;
        padding: var(--space-xl) var(--space-md) var(--space-md);
        position: relative;
        z-index: 2;
      }
    }
  `;
__decorateClass([
  property16({ type: String, attribute: "logo-custom-size" })
], NineTestimonials.prototype, "logoCustomSize", 2);
__decorateClass([
  property16({ type: String, attribute: "logo-margin-bottom" })
], NineTestimonials.prototype, "logoMarginBottom", 2);

// src/modules/testimonials/testimonials.ts
var testimonials_default = NineTestimonials;
NineTestimonials.define("nine-testimonials");

// src/modules/text-intro/text-intro.component.ts
import { css as css39, html as html24 } from "lit";
var NineTextIntro = class extends NineElement {
  render() {
    return html24`
      <section>
        <div class="center">
          <slot></slot>
        </div>
      </section>
    `;
  }
};
NineTextIntro.styles = css39`
    :host {
      display: block;
      padding: calc(var(--section-gap) * 1.5) var(--space-md)
        calc(var(--section-gap) * 1.5 + var(--space-md));
      --gradient-dir: 180deg;
      ${bgGradientAfternoonLight};
      box-shadow: 0 13vh 30vh 15vh #ffefc7;
    }

    .center {
      max-width: var(--page-max-width);
      margin: 0 auto;
    }

    ::slotted([maxWidthIntro]) {
      max-width: ${LAYOUT_SWITCH_AT}px;
      margin: 0 auto;
    }
  `;

// src/modules/text-intro/text-intro.ts
var text_intro_default = NineTextIntro;
NineTextIntro.define("nine-text-intro");
export {
  cards_default as NineCards,
  comparative_table_default as NineComparativeTable,
  content_step_default as NineContentStep,
  content_steps_list_default as NineContentStepsList,
  expandable_items_default2 as NineExpandableItems,
  footer_default as NineFooter,
  hero_default2 as NineHero,
  hero_bullets_default2 as NineHeroBullets,
  image_grid_default as NineImageGrid,
  investors_default as NineInvestors,
  logos_default as NineLogos,
  pricing_default as NinePricing,
  product_default as NineProduct,
  roi_calculator_default2 as NineRoiCalculator,
  side_step_section_default as NineSideStepSection,
  social_proof_default as NineSocialProof,
  stat_items_default as NineStatItems,
  statistics_section_default as NineStatisticsSection,
  steps_list_default as NineStepsList,
  step_list_step_default as NineStepsListStep,
  team_default as NineTeam,
  testimonials_default as NineTestimonials,
  text_intro_default as NineTextIntro
};
